footer {
  width: 100%;
  height: auto;
  background-color: #02112e;
}

footer .footer-container {
  width: 100%;
  height: 100%;
  border-top: 8px solid;
  border-image: linear-gradient(
      to right,
      #fdc609 25%,
      #1cb8cf 25%,
      #1cb8cf 50%,
      #159961 50%,
      #159961 75%,
      #98c222 75%
    )
    5;
  padding: 50px 0px 33px 0px;
}

footer .footer-container .routes-layout .footer-content {
  width: 100%;
}

footer .footer-container .routes-layout .footer-content .row {
  display: flex;
  justify-content: space-between;
}

footer .footer-container .routes-layout .footer-content .row .side {
  width: 50%;
}

footer
  .footer-container
  .routes-layout
  .footer-content
  .row
  .side:nth-child(2) {
  margin-left: 2%;
}

footer .footer-container .routes-layout .footer-content .row:last-child {
  margin-top: 50px;
}

.side.app-logo img {
  max-width: 75%;
}

.side.partners a {
  margin-right: 30px;
}

.side.partners a img {
  max-width: 65px;
}

.side .social-networks a:nth-child(1) {
  margin-right: 21px;
}

.side .social-networks a img {
  max-width: 33px;
}

.side .description {
  margin-top: 30px;
}

.side .description p {
  color: #868a8a;
  font-size: 14px;
  line-height: 22px;
}

.side .description p:last-child {
  margin-top: 30px;
}

@media screen and (max-width: 1024px) {
  footer .footer-container .routes-layout .footer-content .row {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  footer .footer-container .routes-layout .footer-content .row .side {
    width: 100%;
    justify-content: center;
  }

  .side.app-logo {
    margin-bottom: 50px;
  }

  .social-networks,
  .description {
    display: flex;
    justify-content: center;
  }

  .description {
    flex-direction: column;
  }

  .description p {
    text-align: center;
  }
  footer
    .footer-container
    .routes-layout
    .footer-content
    .row:last-child
    .side:last-child {
    margin-top: 50px;
  }
  footer
    .footer-container
    .routes-layout
    .footer-content
    .row:last-child
    .side:last-child
    a {
    justify-content: center;
  }

  .side {
    margin-left: 0 !important;
  }
}

@media screen and (max-width: 480px) {
  .side.app-logo img {
    max-width: 100%;
  }
  .side.partners a img {
    max-width: 55px;
  }
  footer
    .footer-container
    .routes-layout
    .footer-content
    .row:last-child
    .side:last-child
    a
    img {
    max-width: 180px;
  }

  .side.partners {
    justify-content: space-between !important;
  }

  .side.partners a {
    margin-right: 0px;
  }
}

@media screen and (max-width: 315px) {
  .side.partners a img {
    max-width: 45px;
  }
}
