.ant-drawer .ant-drawer-content-wrapper {
  width: 600px;
  min-width: 600px;
  max-width: 600px;
}

.ant-drawer
  .ant-drawer-content-wrapper
  .ant-drawer-wrapper-body
  .ant-drawer-header {
  border: 0px;
  padding: 48.5px 75px 0px 66px;
}

.ant-drawer
  .ant-drawer-content-wrapper
  .ant-drawer-wrapper-body
  .ant-drawer-header
  .ant-drawer-title {
  font-size: 32px;
  line-height: 40px;
  color: #2c3033;
  font-weight: 600;
}

.ant-drawer
  .ant-drawer-content-wrapper
  .ant-drawer-wrapper-body
  .ant-drawer-body {
  padding: 40px 75px 31px 66px;
}

.ant-drawer
  .ant-drawer-content-wrapper
  .ant-drawer-wrapper-body
  .ant-drawer-body
  .add-education-form
  .date-time-box
  .app-date-time-picker {
  width: 48%;
}

.ant-drawer
  .ant-drawer-content-wrapper
  .ant-drawer-wrapper-body
  .ant-drawer-footer {
  padding: 0px;
  background: #f9f9f9;
  border: 0px;
}

.ant-drawer
  .ant-drawer-content-wrapper
  .ant-drawer-wrapper-body
  .ant-drawer-footer
  .drawer-footer {
  padding: 29px 66px;
}

.ant-drawer
  .ant-drawer-content-wrapper
  .ant-drawer-wrapper-body
  .ant-drawer-footer
  .drawer-footer
  button:last-child {
  margin-left: 12px;
}

.ant-drawer
  .ant-drawer-content-wrapper
  .ant-drawer-wrapper-body
  .filter-select {
  margin-right: 0px;
  width: 48%;
}

.ant-drawer
  .ant-drawer-content-wrapper
  .ant-drawer-wrapper-body
  .filter-select
  .ant-select {
  width: 100%;
  background-color: white;
}

.ant-drawer
  .ant-drawer-content-wrapper
  .ant-drawer-wrapper-body
  .custom-mui-field {
  position: relative;
  border: 1px solid #bdc0c7;
  border-radius: 4px;
  padding: 16px 20px;
}

.ant-drawer
  .ant-drawer-content-wrapper
  .ant-drawer-wrapper-body
  .custom-mui-field.counter-field {
  max-width: 194px;
}

.ant-drawer
  .ant-drawer-content-wrapper
  .ant-drawer-wrapper-body
  .custom-mui-field.radio-approvall {
  margin-left: 32px;
}

.ant-drawer
  .ant-drawer-content-wrapper
  .ant-drawer-wrapper-body
  .custom-mui-field
  .radio-btn-label {
  color: #3a4140;
  font-size: 11px;
  line-height: 15px;
  font-weight: 400;
  position: absolute;
  top: -8px;
  left: 14px;
  padding: 0px 6px;
  background-color: white;
}

.ant-drawer
  .ant-drawer-content-wrapper
  .ant-drawer-wrapper-body
  .custom-mui-field
  .radio-btn
  label {
  display: flex;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom-mui-field .radio-btn label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.custom-mui-field .radio-btn label .checkmark {
  font-size: 17px;
  line-height: 26px;
  font-weight: 600;
  color: #3a4140;
  background-color: white;
  padding: 10px 24px;
  border: 1px solid #bdc0c7;
}

.custom-mui-field .radio-btn label:nth-child(1) .checkmark {
  border-right: 0px;
  border-radius: 4px 0px 0px 4px;
}

.custom-mui-field .radio-btn label:nth-child(2) .checkmark {
  border-left: 0px;
  border-radius: 0px 4px 4px 0px;
}

.custom-mui-field .radio-btn label input:checked ~ .checkmark {
  background-color: #003399;
  color: white;
  border-color: #003399;
}

.custom-mui-field .counter-action {
  width: 39px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  line-height: 26px;
  font-weight: 600;
  color: #3a4140;
}

.custom-mui-field .counter-action.decrement,
.custom-mui-field .counter-action.increment {
  cursor: pointer;
}

.custom-mui-field .counter-action.decrement-stop {
  pointer-events: none;
}

.custom-mui-field .counter-action.counter {
  width: 55px;
  border-left: 1px solid #bdc0c7;
  border-right: 1px solid #bdc0c7;
}

.custom-mui-field .counter-holder {
  border: 1px solid #bdc0c7;
  border-radius: 4px;
}

.drawer-spacer {
  margin-bottom: 20px;
}

.add-education-form form .image-upload-info {
  margin-top: 7.5px;
}

@media screen and (max-width: 700px) {
  .ant-drawer .ant-drawer-content-wrapper {
    width: 90%;
    max-width: 90%;
    min-width: 90%;
  }
  .ant-drawer
    .ant-drawer-content-wrapper
    .ant-drawer-wrapper-body
    .ant-drawer-header {
    padding: 24px 40px 24px 40px;
  }
  .ant-drawer
    .ant-drawer-content-wrapper
    .ant-drawer-wrapper-body
    .ant-drawer-body {
    padding: 24px 24px 24px 24px;
  }
  .ant-drawer
    .ant-drawer-content-wrapper
    .ant-drawer-wrapper-body
    .ant-drawer-body
    .date-time-box {
    flex-direction: column;
  }
  .ant-drawer
    .ant-drawer-content-wrapper
    .ant-drawer-wrapper-body
    .ant-drawer-body
    .add-education-form
    .date-time-box
    .app-date-time-picker {
    width: 100%;
  }

  .ant-drawer
    .ant-drawer-content-wrapper
    .ant-drawer-wrapper-body
    .ant-drawer-body
    .add-education-form
    .date-time-box
    .app-date-time-picker:first-child {
    margin-bottom: 20px;
  }

  .ant-drawer
    .ant-drawer-content-wrapper
    .ant-drawer-wrapper-body
    .ant-drawer-body
    .add-education-form
    .date-time-box
    .filter-select {
    width: 100%;
  }

  .ant-drawer
    .ant-drawer-content-wrapper
    .ant-drawer-wrapper-body
    .ant-drawer-body
    .add-education-form
    .date-time-box
    .filter-select:first-child {
    margin-bottom: 20px;
  }

  .ant-drawer
    .ant-drawer-content-wrapper
    .ant-drawer-wrapper-body
    .filter-select {
    width: 100%;
  }

  .ant-drawer
    .ant-drawer-content-wrapper
    .ant-drawer-wrapper-body
    .ant-drawer-footer
    .drawer-footer {
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
  }

  .ant-drawer
    .ant-drawer-content-wrapper
    .ant-drawer-wrapper-body
    .ant-drawer-footer
    .drawer-footer
    button {
    width: 100%;
  }

  .ant-drawer
    .ant-drawer-content-wrapper
    .ant-drawer-wrapper-body
    .ant-drawer-footer
    .drawer-footer
    button:last-child {
    margin-left: 0px;
    margin-top: 20px;
  }

  .ant-drawer
    .ant-drawer-content-wrapper
    .ant-drawer-wrapper-body
    .ant-drawer-body
    .cv-applied {
    flex-direction: column;
  }
  .ant-drawer
    .ant-drawer-content-wrapper
    .ant-drawer-wrapper-body
    .custom-mui-field.radio-approvall {
    margin-left: 0px;
    margin-top: 20px;
  }
  .ant-drawer
    .ant-drawer-content-wrapper
    .ant-drawer-wrapper-body
    .ant-drawer-body
    .add-education-form
    .upload-input-holder
    .upload-input {
    flex-direction: column;
    align-items: flex-start;
  }
  .ant-drawer
    .ant-drawer-content-wrapper
    .ant-drawer-wrapper-body
    .ant-drawer-body
    .add-education-form
    .upload-input-holder
    .upload-input
    label {
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .ant-drawer
    .ant-drawer-content-wrapper
    .ant-drawer-wrapper-body
    .ant-drawer-body
    .add-education-form
    .upload-input-holder
    img.trash {
    top: unset;
    bottom: 9px;
    width: 12px;
  }
}
