.custom-table-list {
  margin-top: 16px;
  margin-bottom: 56px;
}

.item-headers {
  background-color: #cfd6e5;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 24px 32px;
}

.item-headers .column-header {
  flex: 1;
  margin: 0px 22.5px;
}

.item-headers .column-header:first-child {
  max-width: 180px;
  margin-left: 0px;
}

.item-headers .column-header:nth-child(2) {
  max-width: 250px;
}

.item-headers .column-header:last-child {
  margin: 0px;
  flex: unset;
  width: auto;
}

.item-headers .column-header p {
  color: #3a4140;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
}

.item-headers .column-header .sort-icons {
  width: 10px;
  min-width: 10px;
}

.sort-icons img {
  cursor: pointer;
}

.item-headers .column-header .sort-icons img:last-child {
  margin-top: 2px;
}

.item-data {
  background-color: #ffffff;
  border-top: 1px solid #edeeed;
  padding: 32px;
}

.item-data:first-child {
  border-top: 0px;
}

.item-data:last-child {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.item-data .item-column {
  flex: 1;
  margin: 0px 22.5px;
}

.item-data .item-column:first-child {
  margin-left: 0px;
}
.item-data .item-column:last-child {
  margin: 0px;
  display: flex;
  justify-content: flex-end;
  flex: unset;
  width: auto;
  margin-left: auto;
}

.item-data .item-column p {
  color: #3a4140;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  word-break: break-all;
  word-wrap: break-word;
}

.switcher {
  position: relative;
}
