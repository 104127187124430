.start-lesson-modal {
  width: 541px;
}

.start-lesson-modal .modal-top {
  margin-bottom: 32px;
}

.start-lesson-modal .start-header {
  padding: 32px 40px;
}

.start-lesson-modal .start-header .close-lesson-modal {
  margin-top: 14px;
  cursor: pointer;
}
.start-lesson-modal .start-header .countdown {
  margin-left: 21px;
}

@media screen and (max-width: 580px) {
  .start-lesson-modal {
    width: 400px;
  }
}

@media screen and (max-width: 425px) {
  .start-lesson-modal {
    width: 325px;
  }
}

@media screen and (max-width: 325px) {
  .start-lesson-modal {
    width: 100%;
  }
}
