.student-item {
  background-color: #ffffff;
  border-top: 1px solid #edeeed;
  padding: 32px;
}

.student-item:first-child {
  border-top: 0px;
}

.student-item:last-child {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.student-item .item-column {
  flex: 1;
  margin-right: 45px;
}

.student-item .item-column:first-child {
  max-width: 220px;
}

.student-item .item-column:nth-child(2) {
  max-width: 300px;
}

.student-item .item-column:last-child {
  margin-right: 0px;
  display: flex;
  justify-content: flex-end;
}

.student-item .item-column p {
  color: #3a4140;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  word-wrap: break-word;
  word-break: break-all;
}

.switcher {
  position: relative;
}

.student-item.blocked-student .item-column:not(:last-child) {
  opacity: 0.4;
  pointer-events: none;
  user-select: none;
}

.student-item.blocked-student .item-column .show-teachers {
  pointer-events: none;
  user-select: none;
}
