.student-header {
  height: auto;
  background-color: white;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15);
}

.student-header .student-header-content {
  max-width: 1240px;
  margin: 0 auto;
}

.student-header h1 {
  padding: 40px 0px;
}

.student-header .student-pages {
  position: relative;
  display: inline-flex;
}

.student-header .student-pages.my-educations .active-page-indicator {
  transform: translateX(0px);
}

.student-header .student-pages.other .active-page-indicator {
  transform: translateX(194px);
}

.student-header .student-pages .active-page-indicator {
  height: 4px;
  background-color: #295fcc;
  border-radius: 50px 50px 0px 0px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
  transition: all 0.3s;
  width: 40%;
}

.student-header .student-pages .page {
  color: #3a4140;
  font-size: 16px;
  line-height: 21px;
  font-weight: 500;
  padding-bottom: 17px;
  position: relative;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  transition: all 0.3s;
}

.student-header .student-pages .page:first-child {
  margin-right: 40px;
}

.student-header .student-pages .page.disable-tabbars {
  cursor: default;
  user-select: none;
  pointer-events: none;
  opacity: 0.6;
}

#educations-list .list {
  width: calc(100% + 20px);
  margin-left: -10px;
  margin-bottom: 64px;
}

#educations-list .list .education-card {
  margin: 20px 10px 20px 10px;
  flex-basis: 31.7%;
}

@media screen and (max-width: 1366px) {
  #educations-list .list .education-card {
    flex-basis: 31.5%;
  }
}

@media screen and (max-width: 1280px) {
  #educations-list .list .education-card {
    flex-basis: 48%;
  }
  .education-info .first-row .students,
  .education-info .second-row .teacher {
    width: 50%;
  }
  #educations-list .education-filters-holder {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;
  }

  #educations-list .education-filters-holder .search-educations {
    margin-bottom: 16px;
  }

  #educations-list .education-filters-holder .add-new-education {
    margin-bottom: 32px;
  }
}

@media screen and (max-width: 1190px) {
  .student-header {
    padding: 0px;
  }
  .student-header .student-header-content {
    max-width: unset;
    width: 90%;
  }
}

@media screen and (max-width: 1090px) {
  #educations-list .list {
    width: 100%;
    margin-left: 0px;
    justify-content: space-between;
  }
  #educations-list .list .education-card {
    flex-basis: 48%;
    margin-left: 0px;
    margin-right: 0px;
  }
}

@media screen and (max-width: 880px) {
  #educations-list .list {
    justify-content: flex-start;
  }
  #educations-list .list .education-card {
    flex-basis: 100%;
  }
  .education-card .image-holder {
    height: 325px;
  }
  .education-footer {
    padding: 50px 24px;
  }
  #educations-list .filters {
    flex-direction: column;
    align-items: flex-start;
  }
  #educations-list .filters .filter-select {
    width: 280px;
    margin-bottom: 8px;
    margin-right: 0px;
  }
  #educations-list .filters .filter-select .ant-select {
    width: 100%;
  }
  #educations-list .filters .MuiTypography-h5 {
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 414px) {
  .student-header .student-pages {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .student-header .student-pages .page:first-child {
    margin-right: 0px;
  }
  .student-header .student-pages .page {
    font-size: 14px;
    line-height: 19px;
  }
}

@media screen and (max-width: 300px) {
  .education-card .image-holder {
    height: 280px;
  }
  .education-footer {
    padding: 35px 24px;
  }
  #educations-list .filters .filter-select {
    width: 100%;
  }
  #educations-list .education-filters-holder .search-educations {
    width: 100%;
  }
}

#educations-list .filters {
  height: auto;
}

#educations-list .education-filters-holder {
  margin-top: 32px;
  margin-bottom: 21px;
}

#educations-list .filters img.reload-filters {
  cursor: pointer;
  margin-left: 13px;
}

#educations-list .filters .MuiTypography-h5 {
  margin-right: 16px;
  font-weight: 500;
}

.search-educations {
  width: 280px;
  max-width: 280px;
}

.search-educations .MuiFormControl-root .MuiInputBase-root input::placeholder {
  color: #868a89;
}

.search-educations
  .MuiFormControl-root
  .MuiInputBase-root
  .MuiInputAdornment-positionStart {
  margin-right: 0px;
}

.search-educations .MuiFormControl-root {
  background: white;
}

.search-educations.disabled-search .MuiFormControl-root {
  background: #e5e7e7;
}

.search-educations .MuiFormControl-root .MuiInputBase-root {
  height: 40px;
}

.search-educations .MuiFormControl-root .MuiInputBase-root input {
  box-sizing: border-box;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 100%;
  padding-left: 14px;
}
