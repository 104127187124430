#education-timeline.student-timeline {
  padding-top: 80px;
  padding-bottom: 160px;
  padding-right: 100px;
  padding-left: 100px;
  max-width: 1440px;
  margin: 0 auto;
}

#education-timeline .timeline-header {
  margin-bottom: 25px;
}

#education-timeline .timeline-header .swipe-for-more {
  display: none !important;
}

.education-description {
  max-width: 600px;
  margin-bottom: 63px;
}

.education-description p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #3a4140;
  margin-top: 11px;
}

.student-timeline .education-teachers h1 {
  margin-bottom: 18px;
}

.student-timeline .education-teachers .primary-teachers {
  margin-top: 79.5px;
  margin-bottom: 77px;
}

.student-timeline .education-teachers .default-teacher-card {
  flex: 1;
}

.student-timeline .education-teachers .secondary-teachers .secondary-content {
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.student-timeline
  .education-teachers
  .secondary-teachers
  .secondary-content
  .default-teacher-card {
  margin-bottom: 16px;
  margin-right: 20px;
}

@media screen and (max-width: 1190px) {
  .student-timeline
    .education-teachers
    .secondary-teachers
    .secondary-content
    .default-teacher-card {
    width: 46% !important;
  }

  #education-timeline.student-timeline {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 80px;
    max-width: 90%;
  }
}

@media screen and (max-width: 900px) {
  #education-timeline .timeline-header .swipe-for-more {
    display: block !important;
  }
  #education-timeline .timeline-header {
    flex-direction: column;
    align-items: flex-start;
  }
  #education-timeline .timeline-header button.add-event {
    margin-top: 18px;
  }
}

@media screen and (max-width: 768px) {
  .student-timeline
    .education-teachers
    .secondary-teachers
    .secondary-content
    .default-teacher-card {
    width: 100% !important;
    margin-right: 0px;
  }
}

@media screen and (max-width: 600px) {
  #education-timeline .timeline-header h1 {
    font-size: 26px;
    line-height: 34px;
  }
}

#input-copy-link {
  position: absolute;
  left: -9999px;
  top: -9999px;
  opacity: 0;
}

.video-conference-wrapper {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  background-color: red;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
