.teacher-educations {
  position: relative;
  padding: 40px 40px 37px 40px;
  text-align: center;
  min-width: 523px;
  max-width: 523px;
}

@media screen and (max-width: 600px) {
  .teacher-educations {
    min-width: unset;
    width: 400px;
  }
}

@media screen and (max-width: 414px) {
  .teacher-educations {
    width: 350px;
  }
}

@media screen and (max-width: 360px) {
  .teacher-educations {
    padding: 20px 20px 20px 20px;
    width: 320px;
  }
}

.teacher-educations .header {
  position: relative;
  margin-bottom: 12px;
}

.teacher-educations .header p {
  color: #868a89;
  font-size: 16px;
  line-height: 18px;
  font-weight: 400;
  margin-top: 4px;
}

.teacher-educations .header img {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}

.teacher-educations .educations-list {
  height: auto;
}

.teacher-educations .educations-list ul {
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 0px;
}

.teacher-educations .educations-list ul li {
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  color: #3a4140;
  border-bottom: 1px solid #edeeed;
  padding: 25px 0px;
}

.teacher-educations .educations-list ul li img {
  width: 24px;
  height: 24px;
  margin-right: 14px;
}
