.tab-bar {
  width: 100%;
  /* height: 64px;
  background-color: white;
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.15); */
  position: relative;
}

.tab-bar::after {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 64px;
  background-color: white;
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.15);
  content: "";
  z-index: -1;
}

.tab-bar .MuiTabScrollButton-root {
  display: none !important;
}

.tab-bar header.MuiAppBar-root {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0px 100px;
  min-height: 64px;
}

.tab-bar header.MuiAppBar-root .MuiTabs-root {
  min-height: 64px;
}

@media screen and (max-width: 1190px) {
  .tab-bar {
    padding: 0px;
  }
  .tab-bar header.MuiAppBar-root {
    width: 90%;
    padding: 0px;
  }
}

.education-tab-content {
  padding-top: 63px;
  padding-bottom: 56px;
}
