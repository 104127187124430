.student-card .card-header {
  padding: 24px 27px 24px 24px;
}

.student-card .card-header .avatar {
  height: 50px;
  width: 50px;
}

.student-card .card-header .student-info {
  margin-left: 16px;
  margin-right: 16px;
  word-break: break-all;
}

.cv-tooltip {
  background-color: #ffffff !important;
  color: #000000 !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16) !important;
  border-radius: 4px !important;
}

.student-card .card-actions {
  background: #f9f9f9;
  padding: 16px 24px;
}

.cv-section {
  cursor: pointer;
}

.student-card .card-actions button {
  max-height: 46px;
  min-height: 46px;
  width: auto;
  border-radius: 4px;
}

.card-actions button:last-child {
  margin-left: 12px;
  color: #3a4140 !important;
}
