header.single-education {
  width: 100%;
  height: auto;
  min-height: 230px;
  /* max-height: 230px; */
  position: relative;
  background-color: rgba(0, 17, 102, 0.8);
  z-index: 3;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

header.single-education.auto {
  background-size: auto;
  background-color: unset;
}

header.single-education::after {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  content: "";
  background-color: rgba(0, 17, 102, 0.8);
}

header.single-education .header-holder-block {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  padding: 40px 100px;
}

@media screen and (max-width: 1190px) {
  header.single-education .header-holder-block {
    max-width: 90%;
    padding-left: 0px;
    padding-right: 0px;
  }
}

header.single-education .information {
  position: relative;
  z-index: 2;
}

header.single-education .information .breadcrumbs .previous-page {
  color: #73ace5;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.015em;
  font-weight: 500;
  text-decoration: none;
}

header.single-education .information .breadcrumbs .breadcrumb-separator {
  width: 6px;
  height: 8px;
  margin-left: 18px;
  margin-right: 17.6px;
}

header.single-education .information .breadcrumbs .current-page {
  color: white;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: -0.015em;
  font-weight: 400;
}

header.single-education .information .lesson {
  color: white;
  background-color: rgba(255, 127, 59, 0.8);
  border-radius: 2px;
  font-size: 10px;
  line-height: 15px;
  font-weight: 600;
  padding-top: 2.5px;
  padding-bottom: 3.14px;
  padding-left: 6.4px;
  padding-right: 6px;
  display: inline-block;
  text-transform: uppercase;
}

header.single-education .information .lesson.pending {
  background-color: rgba(138, 143, 153, 0.8);
  margin-left: 4px;
}

header.single-education .information .lesson.pending img {
  width: 10px;
  height: 10px;
  margin-right: 5px;
}

header.single-education .education-information .MuiTypography-subtitle2 {
  color: white;
  margin-bottom: 16.5px;
  margin-top: 16.5px;
  max-width: 420px;
  min-width: 420px;
}

header.single-education .education-information .additional-information .date {
  margin-left: 28px;
}

header.single-education
  .education-information
  .additional-information
  .date
  span {
  font-size: 12px;
  line-height: 18px;
  color: #f9f9f9;
  font-weight: normal;
  display: inline-block;
  margin-left: 6.26px;
}

header.single-education
  .education-information
  .additional-information
  .date
  img {
  width: 12px;
  height: 13.33px;
}

header.single-education .actions {
  position: relative;
  z-index: 2;
}

header.single-education .header-holder-block.justify-content-start {
  justify-content: flex-start !important;
}

header.single-education .actions.super-admin-actions {
  width: 100%;
  justify-content: space-between;
}

header.single-education .actions .education-action {
  width: 36px;
  height: 36px;
  border: 1px solid #bdc0c7;
  background-color: rgba(249, 249, 249, 0.7);
  box-shadow: 0px 1px 2px 0px #00000029;
  border-radius: 4px;
  cursor: pointer;
}

header.single-education .actions .education-action.edit-education {
  margin-left: 12px;
}

header.single-education .actions .start-lesson {
  border: 1px solid #bdc0c7;
  border-radius: 4px;
  color: white;
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  padding: 14px 24px;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

header.single-education .actions .apply-now {
  border-radius: 4px;
  color: white;
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  padding: 14px 24px;
  position: relative;
  cursor: pointer;
  background: #1f58cc;
  box-shadow: 0px 2px 4px rgba(31, 88, 204, 0.5);
}

header.single-education .show-chat {
  width: 64px;
  height: 64px;
  cursor: pointer;
  z-index: 2;
  background-color: white;
  border-radius: 50px;
  box-shadow: 0px 3px 6px 0px #77777766;
  position: absolute;
  bottom: -32px;
  right: 100px;
}

@media screen and (max-width: 1190px) {
  header.single-education .show-chat {
    right: 0px;
  }
}

@media screen and (max-width: 700px) {
  header.single-education .header-holder-block {
    padding: 80px 0px;
    flex-direction: column;
    align-items: flex-start;
  }
  header.single-education .header-holder-block .actions {
    margin-top: 30px;
  }
  header.single-education .education-information .MuiTypography-subtitle2 {
    min-width: unset;
    max-width: 100%;
    width: 100%;
    font-size: 32px;
    line-height: 38px;
  }
  header.single-education .education-information .additional-information {
    flex-direction: column;
    align-items: flex-start;
  }
  header.single-education .education-information .additional-information .date {
    margin-left: 0px;
    margin-top: 15px;
  }
}

header.single-education .show-chat img {
  width: 25px;
  height: 25px;
}
