.app-date-time-picker {
  position: relative;
}

.app-date-time-picker .picker-label {
  position: absolute;
  top: -7px;
  left: 9px;
  color: #3a4140;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  background-color: white;
  z-index: 1;
  padding: 0px 6px;
}

.app-date-time-picker .ant-picker {
  border: 1px solid #bdc0c7;
  border-radius: 4px;
  height: 46px;
  box-shadow: unset;
  font-size: 15px;
  line-height: 15px;
  letter-spacing: -0.015em;
  color: #686d6c;
  padding: 0px;
  padding-left: 16px;
  padding-right: 20px;
}
.app-date-time-picker .ant-picker-focused {
  border-color: #003399;
}
.app-date-time-picker.picker-error .ant-picker-focused,
.app-date-time-picker.picker-error .ant-picker {
  border-color: #df2935;
}
.app-date-time-picker.picker-error .ant-picker,
.app-date-time-picker.picker-error .ant-picker input::placeholder {
  color: #df2935;
}
.app-date-time-picker .ant-picker,
.app-date-time-picker .ant-picker .ant-picker-input {
  width: 100%;
}
.app-date-time-picker .ant-picker .ant-picker-input {
  height: 100%;
}
.app-date-time-picker .ant-picker .ant-picker-input .ant-picker-suffix {
  position: relative;
  width: 21px;
  height: 21px;
}
.app-date-time-picker .ant-picker .ant-picker-input .ant-picker-suffix::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: url("/src/assets/images/icons/date-picker-icon.svg");
  width: 100%;
  height: 100%;
}
.app-date-time-picker .ant-picker .ant-picker-input .ant-picker-suffix span {
  display: none;
}
.ant-picker-cell .ant-picker-cell-inner {
  border-radius: 50px;
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background: #003399;
}
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-radius: 50px;
  border-color: #003399;
}
.ant-picker-header-view button:hover {
  color: #003399;
}

.ant-picker-panel .ant-picker-footer .ant-picker-ranges {
  padding: 12px;
}

.ant-picker-panel .ant-picker-footer .ant-picker-ranges .ant-picker-now {
  display: none;
}

.ant-picker-panel .ant-picker-footer .ant-picker-ranges .ant-picker-ok {
  width: 100%;
  margin-left: 0px;
}

.ant-picker-panel .ant-picker-footer .ant-picker-ranges .ant-picker-ok button {
  width: 100%;
  margin: 0px;
  background: #003399;
  border-color: #003399;
  height: 40px;
}

.ant-picker-panel
  .ant-picker-footer
  .ant-picker-ranges
  .ant-picker-ok
  button
  span {
  color: white;
}

.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: #003399;
  color: white;
}

.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner:hover {
  color: black;
}

.ant-picker-time-panel-column {
  width: 125px;
}

.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell
  .ant-picker-time-panel-cell-inner {
  padding: 0px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.error-message {
  color: #df2935;
  margin-left: 14px;
  margin-right: 14px;
  font-size: 15px;
  font-style: normal;
  margin-top: 3px;
  text-align: left;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
}
