.dropdown-checkbox-holder {
  position: relative;
}

.display-none {
  display: none;
}

.display-teachers-count {
  display: flex;
  align-items: center;
  color: #3a4140;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
}

.display-teachers-count.display-none {
  display: none;
}

.dropdown-checkbox-holder
  .ant-select-selection-overflow
  .ant-select-selection-overflow-item {
  display: none !important;
  opacity: 0 !important;
}

.dropdown-checkbox-holder
  .ant-select-selection-overflow
  .ant-select-selection-overflow-item {
  opacity: 0 !important;
}

.dropdown-checkbox-holder .ant-select-selection-overflow {
  height: 100%;
  overflow: hidden;
}

.dropdown-checkbox-holder
  .ant-select-multiple
  .ant-select-selection-item
  .ant-select-selection-item-remove {
  display: none;
}

.dropdown-checkbox-holder .ant-select-selection-overflow-item-suffix {
  width: 0px !important;
  height: 0px !important;
}

filter-select.dropdown-checkbox {
  padding-top: 20px;
}

.dropdown-checkbox .ant-select-item {
  font-size: 15px;
  line-height: 15px;
  color: #3a4140;
  min-height: auto;
  padding: 8px 19px;
  margin-bottom: 4px;
}

.dropdown-checkbox .ant-select-item .ant-select-item-option-content {
  display: flex;
  align-items: center;
}

.dropdown-checkbox .ant-select-item-option-selected {
  background: #dcf7e1;
  font-weight: 400;
}

.dropdown-checkbox .ant-select-item-option-selected:hover {
  background: #dcf7e1 !important;
}

.dropdown-checkbox .option-checkbox {
  width: 18px;
  height: 18px;
  border: 1px solid #bdc0c7;
  border-radius: 2px;
  margin-right: 12px;
  transition: all 0.3s;
}

.dropdown-checkbox .option-checkbox img {
  transition: all 0.3s;
  opacity: 0;
}

.dropdown-checkbox
  .ant-select-item-option-selected
  .ant-select-item-option-content
  .option-checkbox {
  background-color: #35b84b;
  border-color: #35b84b;
}

.dropdown-checkbox
  .ant-select-item-option-selected
  .ant-select-item-option-content
  .option-checkbox
  img {
  opacity: 1;
}

.dropdown-checkbox .ant-select-item-option-state {
  display: none;
}
