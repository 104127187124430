#main-navigation-holder {
  background: white;
  width: 100%;
  height: 80px;
  padding: 0px 100px;
  box-shadow: 0px 1px 5px 1px #00000026;
}

nav#app-navigation {
  height: 100%;
  max-width: 1240px;
  margin: 0 auto;
}

nav#app-navigation .nav-links {
  margin-left: 84px;
}
nav#app-navigation .nav-links a {
  display: flex;
  align-items: center;
  margin-right: 40px;
  color: #3a4140;
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  text-decoration: none;
}
nav#app-navigation .nav-links a.active-nav-item {
  color: #fc7914;
}
nav#app-navigation .nav-links a:last-child {
  margin-right: 0px;
}
nav#app-navigation .nav-links a img {
  margin-right: 10px;
  width: 22px;
  height: 22px;
}
nav#app-navigation .right-side .languages {
  margin-right: 23px;
}
.role-name-navigation {
  font-size: 14px;
  line-height: 16px;
  color: #3a4140;
  font-weight: 400;
  letter-spacing: -0.015em;
  margin-right: 20px;
}
.role-name-navigation.hub-admin-role {
  min-width: 78px;
}
nav#app-navigation .right-side .languages .bih {
  margin-right: 12.5px;
}
.role-name-mobile {
  display: none;
}
.languages .bih,
.languages .eng {
  font-size: 14px;
  line-height: 18px;
  color: #3a4140;
  font-weight: 400;
  letter-spacing: -0.015em;
}
.languages .bih.active,
.languages .eng.active {
  font-weight: 600;
}
.languages-dropdown-ant-design {
  background-color: white;
  padding: 10px 8px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
}
.languages-dropdown-ant-design .bih {
  margin-bottom: 7px;
}
.languages-dropdown-ant-design .active {
  cursor: default;
  user-select: none;
  pointer-events: none;
}
nav#app-navigation .right-side .user-data {
  position: relative;
}
nav#app-navigation .right-side .user-data .notification {
  margin-right: 23px;
  position: relative;
}

.user-data .notification .new-notification {
  background: #fc7914;
  width: 12px;
  height: 12px;
  position: absolute;
  right: -4px;
  top: 3px;
  border-radius: 50%;
}

.hamburger {
  display: none;
  cursor: pointer;
}

.languages-dropdown {
  display: flex;
}

.languages-dropdown img {
  margin-left: 10px;
}

.languages-dropdown p {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #3a4140;
  letter-spacing: -0.015em;
}

@media screen and (max-width: 1190px) {
  #main-navigation-holder {
    padding: 0px;
  }
  nav#app-navigation {
    max-width: unset;
    width: 90%;
  }
  nav#app-navigation .nav-links {
    margin-left: 55px;
  }
  nav#app-navigation .nav-links a {
    margin-right: 40px;
  }
}

@media screen and (max-width: 1100px) {
  nav#app-navigation .nav-links {
    display: none;
  }
  .hamburger {
    display: block;
  }
  .logout {
    display: none;
  }
  .languages {
    display: none;
  }
  .role-name-mobile {
    display: block;
  }
  .role-name-desktop {
    display: none;
  }
}

@media screen and (max-width: 414px) {
  nav#app-navigation > .logo {
    width: 180px !important;
    min-width: 180px !important;
  }
  nav#app-navigation > .logo img {
    width: 100%;
  }
}
