.lesson-wrapper {
  padding-top: 45px;
  padding-bottom: 55px;
}

.lesson-wrapper .lesson-content .lesson {
  width: 820px;
  height: auto;
}

.lesson-wrapper .lesson-content .lesson .lesson-header .end-lesson {
  padding: 10px 24px;
  border-radius: 4px;
  border: 1px solid #bdc0c7;
  font-size: 17px;
  line-height: 26px;
  font-weight: 600;
  color: #3a4140;
  cursor: pointer;
}

.lesson-wrapper .lesson-content .lesson .video-lesson-wrapper {
  height: 500px;
  max-height: 500px;
  overflow: hidden;
  border-radius: 8px;
  border: 1px solid #3a4140;
  margin-top: 16px;
  margin-bottom: 32px;
}

.lesson-wrapper
  .lesson-content
  .lesson
  .lesson-participants
  .participants-list {
  height: auto;
  max-height: 300px;
  overflow-y: auto;
  background-color: white;
  padding: 24px 24.84px;
  border-radius: 8px;
  margin-top: 8px;
}

.lesson-wrapper
  .lesson-content
  .lesson
  .lesson-participants
  .participants-list
  .participant {
  border-bottom: 1px solid #edf0f5;
  padding-top: 12.07px;
  padding-bottom: 11.07px;
}

.lesson-wrapper
  .lesson-content
  .lesson
  .lesson-participants
  .participants-list
  .participant:nth-child(1) {
  padding-top: 0px;
}

.lesson-wrapper
  .lesson-content
  .lesson
  .lesson-participants
  .participants-list
  .participant
  .info
  img {
  width: 40px;
  height: 40px;
  margin-right: 12.5px;
}

.lesson-wrapper
  .lesson-content
  .lesson
  .lesson-participants
  .participants-list
  .participant
  .info
  p {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: #868a89;
}

.lesson-wrapper
  .lesson-content
  .lesson
  .lesson-participants
  .participants-list
  .participant
  .participant-action
  p {
  font-size: 15px;
  line-height: 23px;
  font-weight: 400;
  color: #3a4140;
  margin-right: 30px;
}

.lesson-content .chat {
  width: 100%;
  background-color: white;
  border-radius: 12px;
  height: 100%;
}

.lesson-content .chat .chat-header {
  border-radius: 12px 12px 0px 0px;
  background-color: #f9f9f9;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  padding: 8px;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lesson-content .chat .chat-header h3 {
  text-align: center;
  color: #000000;
  font-size: 24px !important;
  line-height: 32px !important;
  font-weight: 600 !important;
}

.lesson-content .chat .close-chat {
  width: 15px;
  height: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lesson-content .chat .close-chat .minimize-icon {
  width: 100%;
  height: 2px;
  background-color: #3a4140;
}

.lesson-content .chat .chat-content {
  padding: 0px 20px 40px 20px;
  height: 100%;
}

.lesson-content .chat .chat-content .chat-messages {
  overflow-y: auto;
  height: calc(100% - 109px);
}

.lesson-content .chat .chat-content .message {
  margin-bottom: 16px;
}

.lesson-content .chat .chat-content .message:nth-child(1) {
  margin-top: 16px;
}

.lesson-content .chat .chat-content .message img {
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
  margin-right: 12px;
}

.lesson-content .chat .chat-content .message .message-content {
  background: #daf7df;
  border-radius: 4px;
  padding: 12px;
  word-break: break-all;
  width: 100%;
  max-width: 100%;
}

.lesson-content .chat .chat-content .message .message-content p {
  color: #3a4140;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
}

.lesson-content .chat .chat-content .message .message-content p.message-text {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  margin: 4px 0px;
}

.lesson-content
  .chat
  .chat-content
  .message
  .message-content
  span.message-time {
  color: #686d6c;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  text-align: right;
}

.lesson-content .new-message .send-message-input form {
  justify-content: space-between;
}

.lesson-content
  .new-message
  .send-message-input
  form
  .send-message-input-field {
  width: 70%;
}

.lesson-content
  .new-message
  .send-message-input
  form
  .send-message-btn:not(.Mui-disabled) {
  background-color: #003399;
  color: white;
}
