.add-hub {
  width: 1106px;
}

.add-hub .form-block {
  width: 48%;
}

.add-hub .form-holder {
  padding: 48px 64px;
}

.add-hub .form-holder .hub-title {
  margin-bottom: 32px;
}

.add-hub .form-holder .hub-title img {
  cursor: pointer;
}

.add-hub .hubs-info .info-label {
  background-color: #d2fcfc;
  padding: 9px;
}

.add-hub .hubs-info .info-label .info-icon {
  height: 16px;
}

.add-hub .hubs-info .info-label .info-text p {
  margin-left: 6px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #3a4140;
}

.add-hub .form-separator {
  margin-bottom: 20px;
}

.add-hub .modal-content .footer {
  padding-left: 64px;
}

.add-hub form .image-upload-info {
  margin-top: 7.5px;
}

.input-number,
.input-city {
  width: 48% !important;
}

@media screen and (max-width: 700px) {
  .input-number,
  .input-city {
    width: 100% !important;
  }
  .date-time-box .input-number:first-child {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 1100px) {
  .add-edit-hub-modal .app-modal-container {
    width: 90%;
  }
  .add-edit-hub-modal .app-modal-container .add-hub {
    width: 100%;
  }
  .add-edit-hub-modal .app-modal-container .add-hub .form-block-holder {
    flex-direction: column-reverse;
  }
  .add-edit-hub-modal
    .app-modal-container
    .add-hub
    .form-block-holder
    .form-block {
    width: 100%;
  }
  .add-edit-hub-modal
    .app-modal-container
    .add-hub
    .form-block-holder
    .form-block.hubs-info {
    margin-top: 30px;
  }
}

@media screen and (max-width: 620px) {
  .add-edit-hub-modal .add-hub .form-holder {
    padding-left: 30px;
    padding-right: 30px;
  }
  .add-edit-hub-modal .hub-title h6 {
    font-size: 30px;
    line-height: 34px;
  }
  .add-edit-hub-modal .add-hub .modal-content .footer {
    padding-left: 36px;
  }
  .add-edit-hub-modal .add-hub .form-block .flex-column-xs {
    flex-direction: column;
  }
  .add-edit-hub-modal .add-hub .form-block .flex-column-xs .hub-select,
  .input-city,
  .input-number {
    width: 100% !important;
  }
  .add-edit-hub-modal .add-hub .form-block .flex-column-xs .hub-select,
  .add-hub .form-block .flex-column-xs .input-number:first-child {
    margin-bottom: 20px;
  }
  .add-edit-hub-modal .add-hub .form-block .upload-input label {
    padding: 6px 10px;
    font-size: 12px;
  }
  .add-edit-hub-modal .add-hub .form-block .upload-input p {
    width: 200px;
  }
}

@media screen and (max-width: 414px) {
  .add-edit-hub-modal .add-hub .form-block .upload-input {
    flex-direction: column;
    align-items: flex-start;
  }
  .add-edit-hub-modal .add-hub .form-block .upload-input p {
    margin-top: 15px;
  }
  .add-edit-hub-modal .add-hub .form-block .upload-input-holder img.trash {
    top: 9px;
  }
}
