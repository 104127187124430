.item-data .item-column.item-column-hub {
  max-width: 80px;
}

@media screen and (max-width: 1366px) {
  .item-data .item-column.item-column-hub {
    max-width: 220px;
  }
}

.item-data .item-column.item-column-email {
  width: 250px;
  min-width: 250px;
  max-width: 250px;
}

.item-data.blocked-teacher .item-column:not(:last-child) {
  opacity: 0.4;
  pointer-events: none;
  user-select: none;
}

.item-data.blocked-teacher .item-column .show-teachers {
  pointer-events: none;
  user-select: none;
}

.item-data .item-column.item-column-name {
  width: 180px;
  min-width: 180px;
  max-width: 180px;
}

.item-data .item-column.item-column-primary-teacher {
  width: 170px;
  min-width: 170px;
  max-width: 170px;
}

.item-data .item-column.item-column-secondary-teacher {
  width: 190px;
  min-width: 190px;
  max-width: 190px;
}

.item-column .show-teachers {
  border: 1px solid #bdc0c7;
  border-radius: 4px;
  padding: 6px 16px;
  color: #3a4140;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  cursor: pointer;
}
