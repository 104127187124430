.education-students {
  margin-bottom: 18px !important;
}

.students-for-education .list-students {
  width: calc(100% + 20px);
  margin-left: -10px;
}

.students-for-education .list-students .student-card {
  margin: 0px 10px 20px 10px;
  flex-basis: 31.7%;
}

@media screen and (max-width: 1403px) {
  .students-for-education .list-students .student-card {
    flex-basis: 48%;
  }
}

@media screen and (max-width: 1088px) {
  .students-for-education .list-students .student-card {
    flex-basis: 47%;
  }
}

@media screen and (max-width: 880px) {
  .students-for-education .list-students .student-card {
    flex-basis: unset;
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
}
