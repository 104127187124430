.padding-right-layout {
  padding-right: 100px;
}
.padding-left-layout {
  padding-left: 100px;
}
.padding-layout {
  padding: 0px 100px;
}

.padding-top-layout {
  padding-top: 64px;
}

.padding-bottom-layout {
  padding-bottom: 64px;
}

.overflow-hidden {
  overflow: hidden;
}

.routes-layout {
  max-width: 1440px;
  margin: 0 auto;
}

@media screen and (max-width: 1190px) {
  .routes-layout {
    padding-left: 0px !important;
    padding-right: 0px !important;
    margin: 0 auto;
    width: 90%;
    max-width: 100%;
  }
}
