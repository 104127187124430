.add-teacher {
  padding-top: 32px;
  width: 539px;
}

.add-teacher .teacher-header {
  padding: 0px 40px;
}

.add-teacher .teacher-header h1 {
  color: #2c3033;
}

.add-teacher .teacher-header img {
  cursor: pointer;
}

.form-content {
  margin: 24px 40px;
}

@media screen and (max-width: 600px) {
  .add-teacher {
    width: 414px;
  }

  .add-teacher form .footer {
    flex-direction: column;
  }

  .add-teacher form .footer button {
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
  }

  .add-teacher form .footer button:first-child {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 440px) {
  .add-teacher {
    width: 380px;
  }

  .add-teacher .teacher-header h1 {
    font-size: 24px;
    line-height: 28px;
  }
}

@media screen and (max-width: 390px) {
  .add-teacher {
    width: 360px;
  }
  .add-teacher .teacher-header {
    padding: 0px 20px;
  }
  .add-teacher .form-content {
    margin: 24px 20px;
  }
  .add-teacher form .footer {
    padding: 24px 20px;
  }
}

@media screen and (max-width: 370px) {
  .add-teacher {
    width: 340px;
  }
}

@media screen and (max-width: 350px) {
  .add-teacher {
    width: 300px;
  }
}

@media screen and (max-width: 320px) {
  .add-teacher {
    width: 280px;
  }
}
