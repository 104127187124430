.column-header.column-header-hub {
  max-width: 80px;
}

.column-header.column-header-email {
  width: 250px;
  min-width: 250px;
  width: 250px;
}

.column-header.column-header-name {
  width: 180px;
  min-width: 180px;
  width: 180px;
}

.column-header.column-header-primary_teacher {
  max-width: 170px;
}

.column-header.column-header-secondary_teacher {
  max-width: 190px;
}

@media screen and (max-width: 1366px) {
  .teachers-list .custom-table-list {
    overflow-x: auto;
  }

  .teachers-list .custom-table-list .item-headers,
  .teachers-list .custom-table-list .item-data {
    width: 1360px;
  }

  .column-header.column-header-hub {
    max-width: 220px;
  }
}

@media screen and (max-width: 414px) {
  .teachers-list .teachers-header {
    flex-direction: column;
    align-items: flex-start;
  }
  .teachers-list .teachers-header button {
    margin-top: 18px;
  }
}
