.hub-card {
  margin-bottom: 24px;
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.hub-card .blocked-hub {
  background-color: #ebf0fa;
  opacity: 0.7;
  user-select: none;
  pointer-events: none;
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.hub-card .card-media {
  min-width: 620px;
  height: 452px;
}

.hub-card .card-no-picture {
  background-color: #bdc0c7;
}

.hub-card .card-no-picture img {
  width: 80px;
  margin-right: 32px;
}

.hub-card .card-no-picture h6 {
  color: #2c3033;
}

.hub-card .card-content {
  width: 100%;
  padding: 63px 47px 0px 47px;
}
.card-content .header .title {
  color: #2c3033;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 48px;
  letter-spacing: -0.01em;
}

.card-content .header .btn button {
  position: relative;
  z-index: 1;
  background: #f9f9f9;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
}

.header .btn button:last-child {
  margin-left: 12px;
}

.header .btn button img {
  width: 22px;
  height: 22px;
}
.header .btn button:last-child img {
  width: 25px;
  height: 25px;
}

.hub-card .hub-info {
  margin-top: 32px;
}

.hub-info .admin-info {
  margin-bottom: 24px;
  word-break: break-all;
}

.admin-info p {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: -0.015em;
  text-align: left;
}

@media screen and (max-width: 1400px) {
  .hub-card .card-media {
    min-width: 500px;
  }
}

@media screen and (max-width: 1280px) {
  .hub-card .card-media {
    min-width: 400px;
  }
  .hub-card .card-no-picture h6 {
    font-size: 30px;
    line-height: 34px;
  }
  .hub-card .card-no-picture img {
    width: 55px;
  }
}

@media screen and (max-width: 1100px) {
  .hub-card {
    flex-direction: column;
  }
  .hub-card .card-content {
    padding-bottom: 39px;
  }
  .hub-list .card-content .btn {
    position: absolute;
    top: 47px;
    right: 47px;
  }
  .hub-card .card-media {
    min-width: unset;
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .hub-card .card-media {
    height: 330px;
  }
  .card-content .header .title {
    font-size: 28px;
    line-height: 32px;
  }
  .card-content .hub-info .admin-info h4 {
    font-size: 18px;
    line-height: 24px;
  }
  .hub-list .header {
    flex-direction: column;
  }
  .hub-list .header button.btn-add-hub {
    margin-top: 20px;
  }
}
