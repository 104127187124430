.education-card {
  position: relative;
  cursor: pointer;
}

.education-card.blocked-education .blocked-element {
  width: 100%;
  height: 100%;
  background-color: #f1f5fc;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  opacity: 0.7;
}

.education-card .card-no-picture {
  background-color: #bdc0c7;
  height: 100%;
}

.education-card .card-no-picture img {
  width: 50px;
  margin-right: 22px;
}

.education-card .card-no-picture h6 {
  color: #2c3033;
  font-size: 22px;
  line-height: 26px;
}

.education-card.blocked-education {
  user-select: none;
  pointer-events: none;
  cursor: default;
}

.blocked-education .unblock-btn {
  min-height: auto;
  min-width: auto;
  width: auto;
  height: auto;
  top: 20px;
  left: 20px;
  background-color: #ffffff;
  pointer-events: auto;
  position: absolute;
  cursor: pointer;
  padding: 11px 13px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  z-index: 1;
}

.education-card .card-img {
  position: relative;
  height: 100%;
}

.education-card .education-info {
  color: #3a4140;
}

.education-card .apply-button {
  margin-top: 24px;
}

.education-footer {
  padding: 30px 24px;
}

.education-footer h3 {
  margin-bottom: 20px;
}

.education-info .first-row {
  flex-wrap: wrap;
}

.education-info .second-row {
  margin-top: 8px;
}

.education-info .icons {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.education-info .first-row .students,
.education-info .second-row .teacher {
  width: 50%;
  cursor: pointer;
}

.education-info .first-row .holder,
.education-info .second-row .holder {
  width: 45%;
  margin-top: 8px;
}

.education-card .holder .hub-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.education-card .image-holder {
  position: relative;
  height: 220px;
}

.education-card .image-holder .user-status {
  position: absolute;
  left: 24px;
  top: 24px;
  background-color: white;
  border-radius: 2px;
  padding: 3px 8px;
  color: #3a4140;
  font-size: 10px;
  line-height: 15px;
  font-weight: 500;
  z-index: 2;
}

.education-card .image-holder .user-status.dissaproved {
  color: #e5000f;
}

.education-card .image-holder .user-status.approved {
  color: #0da827;
}

.education-card .image-holder .user-status img {
  width: 10px;
  height: 10px;
  margin-right: 5px;
}

.education-card .image-holder .labels {
  margin-left: 24px;
  border-radius: 2px;
  position: absolute;
  bottom: -10px;
  text-transform: uppercase;
}

.education-card .image-holder .labels span {
  background: #ec5707;
  padding: 2px 8px;
  border-radius: 2px;
  color: #ffffff;
  font-size: 11px;
  font-style: normal;
  font-weight: 600;
}
.education-card .image-holder .labels .active {
  background-color: #0ad12c;
}

.education-card .image-holder .labels .finalized {
  background-color: #ed0716;
}

.education-card .image-holder .labels .scheduled {
  background-color: #8a8f99;
}

.labels span:last-child {
  margin-left: 4px;
}

.education-info .tooltip {
  background-color: #ffffff !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25) !important;
  border-radius: 4px !important;
  padding: 16px;
}
.education-info .tooltip.show {
  opacity: 1 !important;
}

.education-info .registered-users p {
  margin-bottom: 8px;
  color: #3a4140;
}

.education-info .registered-users p:last-child {
  margin-bottom: 0px;
}

.education-info .first-row .students-tooltip {
  margin: 16px 0px 16px 16px !important;
}

.education-info .second-row .teacher-tooltip {
  margin: 16px 0px 8px 20px !important;
}

.teacher .columns .primary {
  margin-right: 24px;
}

.teacher .columns .column-title {
  font-weight: 600;
  color: #3a4140;
}

.block-info {
  margin-top: 34px;
  background: #9eedf7;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding: 14.5px;
  position: relative;
  z-index: 2;
}

.block-info .info-icon {
  margin-right: 11px;
}

@media screen and (max-width: 414px) {
  #educations-list .list .education-card .education-info .first-row,
  #educations-list .list .education-card .education-info .second-row {
    flex-direction: column;
  }
  #educations-list .list .education-card .education-info .second-row {
    margin-top: 14px;
  }
  #educations-list .list .education-card .education-info .first-row .students,
  #educations-list .list .education-card .education-info .second-row .teacher {
    width: unset;
    margin-top: 14px;
  }

  #educations-list .list .education-card .education-info .first-row .holder {
    width: 100%;
  }
}

.apply-button button {
  font-size: 18px;
  line-height: 26px;
  font-weight: 600 !important;
  padding: 14px 24px;
}

.apply-button button span {
  font-weight: 600 !important;
}
