.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

.height-100v {
  height: 100vh;
}
