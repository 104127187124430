.snackbar-item {
  position: fixed;
  bottom: 60px;
  left: 60px;
  border-radius: 4px;
  box-shadow: 0px 5px 10px 0px #00000040;
  padding: 14px;
  padding-right: 19px;
  z-index: 100000;
}

.snackbar-item p {
  color: white;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.015em;
  font-weight: 600;
  margin-right: 70px;
}

.snackbar-item .info {
  width: 20px;
  height: 20px;
  margin-right: 14px;
}

.snackbar-item .close {
  width: 10.5px;
  height: 10.5px;
  cursor: pointer;
}

.snackbar-item.success {
  background: #11ad24;
}

.snackbar-item.error {
  background: #e5000f;
}
