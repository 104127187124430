.auth-container {
  width: 100%;
}

.auth-container .auth-content {
  width: 100%;
  height: 100%;
  align-items: center;
}

.auth-container .auth-content .auth-form {
  width: 583px;
  min-width: 583px;
  height: 100%;
  background-color: white;
}

.auth-container .auth-content .auth-form.login-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.auth-container .auth-content .auth-form .data-holder {
  margin-top: 40px;
  padding: 0px 101px;
}

.auth-container .auth-content .auth-form .form-box {
  margin-top: 164px;
}

.auth-container .auth-content .auth-form .form-box a.link {
  color: #003399;
  font-weight: 600;
  font-size: 15px;
  line-height: 15px;
  text-decoration: none;
}

.auth-container .auth-content .auth-form .form-box a.forgot-password-link {
  margin-top: 16px;
}

.auth-container .auth-content .auth-form .form-box p.need-account {
  text-align: center;
  margin-top: 20px;
}

.auth-container .auth-content .auth-form .form-box p.need-account a {
  margin-left: 7px;
}

.auth-container .auth-content .auth-form .form-box button {
  margin-top: 40px;
}

.info-message-reset-password {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 17, 102, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.info-message-reset-password .box {
  width: auto;
  max-width: 530px;
  height: auto;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 20px 40px 0px #00000040;
  margin-top: 110px;
  padding: 55px 68px 62px 68px;
}

.info-message-reset-password .box img {
  width: 185px;
  display: block;
  margin: 0 auto;
}

.info-message-reset-password .box .h1 {
  margin-top: 33px;
  margin-bottom: 12px;
  text-align: center;
}

.info-message-reset-password .box p {
  color: #3a4140;
  margin-bottom: 39px;
  text-align: center;
}

.info-message-reset-password .box button {
  max-width: 383px;
  text-transform: capitalize;
}

.auth-ilustration {
  padding-left: 48px;
  padding-right: 39px;
  background: #ebf0fa;
}

.disclaimer {
  width: 90%;
  height: auto;
  margin: 0 auto;
  margin-bottom: 40px;
}

.disclaimer p {
  color: #686d6c;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: -0.015em;
  text-align: center;
}

.disclaimer p:first-child {
  margin-bottom: 40px;
}

@media screen and (max-width: 1440px) {
  .auth-ilustration img {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .auth-container {
    background-color: white;
  }
  .auth-ilustration {
    display: none;
  }
  .auth-container .auth-content .auth-form {
    width: 100%;
    min-width: unset;
    overflow-y: auto;
  }
}

@media screen and (max-width: 630px) {
  .auth-container .auth-content .auth-form .form-box {
    margin-top: 100px;
  }
  .auth-container .auth-content .auth-form .data-holder {
    padding: 0px;
    width: 90%;
    margin: 40px auto;
  }
  .disclaimer {
    margin-bottom: 0px;
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 480px) {
  .auth-container {
    height: 100vh;
  }
}

@media screen and (max-width: 414px) {
  .auth-container .auth-content .auth-form .form-box h6 {
    font-size: 30px;
    line-height: 34px;
  }
}

@media screen and (max-width: 360px) {
  .auth-container .auth-content .auth-form .logo {
    width: 100% !important;
    min-width: unset !important;
  }
}
