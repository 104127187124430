.qa-section .qa-list {
  margin-top: 20px;
}

@media screen and (max-width: 600px) {
  .qa-section .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .qa-section .header button.add-question {
    margin-top: 18px;
  }

  .qa-section .header h1 {
    font-size: 26px;
    line-height: 34px;
  }
}
