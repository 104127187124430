.student-status-content {
  text-align: center;
  max-width: 396px;
  border-radius: 12px !important;
}
.student-status-content .modal-header {
  padding: 40px 47px 24px 47px;
}

.student-status-content .modal-icon img {
  border-radius: 8px;
  padding: 24px 16px;
  background: #f2f5fa;
}
.student-status-content .modal-title {
  margin-top: 20px;
  margin-bottom: 12px;
}
.modal-title h1 {
  color: #2c3033 !important;
}

.student-status-content .modal-actions {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: #f9f9f9;
  padding: 24px 0;
}
.student-status-content .modal-actions button {
  max-height: 54px;
  min-height: 54px;
  width: auto;
  padding: 14px 24px;
  font-size: 18px;
  line-height: 26px;
}

.student-status-content .modal-actions button span {
  font-weight: 600 !important;
}

.student-status-content .modal-actions button:first-child {
  margin-right: 12px;
}
