.live-chat {
  width: 100%;
  height: 100vh;
  background-color: rgba(44, 48, 51, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  padding: 50px 0px;
  overflow-y: auto;
}

.live-chat .live-chat-container {
  width: 1208px;
  box-shadow: 0px 5px 10px 0px #00000040;
  background-color: white;
  border-radius: 12px;
  margin: 0 auto;
}

.live-chat .live-chat-container .live-chat-header {
  background: #f9f9f9;
  box-shadow: 0px 2px 4px 0px #00000040;
  border-radius: 12px 12px 0px 0px;
  position: relative;
  padding: 18px 0px;
}

.live-chat .live-chat-container .live-chat-header h2 {
  text-align: center;
}

.live-chat .live-chat-container .live-chat-header img {
  position: absolute;
  top: 27px;
  bottom: 0;
  right: 33px;
  cursor: pointer;
  width: 17.5px;
  height: 17.5px;
}

.live-chat .live-chat-container .live-chat-wrapper {
  height: auto;
}

.live-chat .live-chat-container .message {
  margin-bottom: 16px;
}

.live-chat .live-chat-container .message:nth-child(1) {
  margin-top: 16px;
}

.live-chat .live-chat-container .message img {
  width: 48px;
  height: 48px;
  margin-right: 12px;
}

.live-chat .live-chat-container .live-chat-wrapper .chat-messages {
  height: auto;
  max-height: 500px;
  overflow-y: auto;
  padding: 0px 64px;
}

.live-chat .live-chat-container .message .message-content {
  background: #daf7df;
  border-radius: 4px;
  padding: 12px;
}

.live-chat .live-chat-container .message .message-content p {
  color: #3a4140;
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
}

.live-chat .live-chat-container .message .message-content p.message-text {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  margin: 4px 0px;
}

.live-chat .live-chat-container .message .message-content span.message-time {
  color: #686d6c;
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  text-align: right;
}

.live-chat .live-chat-container .new-message {
  border-radius: 0px 0px 12px 12px;
  background-color: #f9f9f9;
  padding: 25px 124px;
  height: auto;
}

.live-chat .live-chat-container .new-message .new-message-input {
  width: 630px;
}

.no-messages-in-chat {
  border: 1px solid #003399;
  border-radius: 4px;
  padding: 20px;
  margin: 30px 0px;
}

@media screen and (max-width: 1240px) {
  .live-chat .live-chat-container {
    width: 90%;
  }
  .live-chat .live-chat-container .new-message .new-message-input {
    width: 100%;
  }
}

@media screen and (max-width: 690px) {
  .live-chat .live-chat-container .live-chat-wrapper .chat-messages {
    padding: 0px 32px;
  }
  .live-chat .live-chat-container .new-message {
    padding: 25px 90px;
  }
}

@media screen and (max-width: 520px) {
  .live-chat .live-chat-container .live-chat-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 32px;
  }
  .live-chat .live-chat-container .live-chat-header h2 {
    font-size: 18px;
    line-height: 24px;
    text-align: left;
  }
  .live-chat .live-chat-container .live-chat-header img {
    position: relative;
    left: unset;
    top: unset;
    right: unset;
    width: 14px;
    height: 14px;
  }
  .live-chat .live-chat-container .new-message {
    padding: 25px 32px;
  }
}

@media screen and (max-width: 414px) {
  .live-chat .live-chat-container .message img {
    width: 32px;
    height: 32px;
  }
}
