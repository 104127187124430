#notifications-list {
  height: auto;
  padding-top: 64px;
  padding-bottom: 58.5px;
}

#notifications-list .MuiTypography-h1 {
  color: #2c3033;
}

#notifications-list .notifications {
  margin-top: 16px;
  margin-bottom: 56px;
}

#notifications-list .notifications .one-notification {
  box-shadow: 0px 2px 4px 0px #00000026;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 24px;
}

#notifications-list .notifications .one-notification:last-child {
  margin-bottom: 0px;
}

#notifications-list .notifications .one-notification p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  margin-left: 12px;
}

#notifications-list .notifications .one-notification a {
  color: #0c59f2;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 8px;
}

#notifications-list .no-notifications {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  margin-top: 40px;
}
