.tox .tox-toolbar__primary {
  background-color: #d8e2f2 !important;
  background: #d8e2f2 !important;
}

.tox .tox-editor-container {
  border-radius: 8px !important;
}

.tox-tinymce {
  border: 0px !important;
}

.tox .tox-edit-area__iframe {
  background: #e6edf7 !important;
  background-color: #e6edf7 !important;
}
