.students-list .list {
  margin-top: 16px;
  margin-bottom: 56px;
}

.students-list .item-headers {
  background-color: #cfd6e5;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: 24px 32px;
}

.students-list .item-headers .column-header {
  flex: 1;
  margin-right: 45px;
}

.students-list .item-headers .column-header:first-child {
  max-width: 220px;
}

.students-list .item-headers .column-header:nth-child(2) {
  max-width: 300px;
  margin-left: 0px;
}

.students-list .item-headers .column-header:last-child {
  margin-right: 0px;
}

.students-list .item-headers .column-header p {
  color: #3a4140;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
}

.students-list .item-headers .column-header .sort-icons {
  width: 10px;
  min-width: 10px;
}

.sort-icons img {
  cursor: pointer;
}

.sort-icons img.selected-arrow {
  cursor: default;
  user-select: none;
  pointer-events: none;
  opacity: 0.5;
}

.students-list .item-headers .column-header .sort-icons img:last-child {
  margin-top: 2px;
}

@media screen and (max-width: 510px) {
  .students-list .list {
    overflow-x: auto;
  }

  .students-list .item-headers,
  .students-list .student-item {
    width: 720px;
  }
}
