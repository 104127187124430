.send-message-input {
  border: 1px solid #bdc0c7;
  border-radius: 4px;
  background-color: white;
  padding: 9px;
  transition: all 0.3s;
}

.send-message-input.active-input {
  border-color: #003399;
}

.send-message-input form .send-message-btn {
  margin-left: 14px;
}
