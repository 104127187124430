.image-upload-info {
  background: #d2fcfc;
  border-radius: 4px;
  padding: 10px;
}

.image-upload-info img {
  width: 16px;
  height: 16px;
  margin-right: 6px;
}

.image-upload-info p {
  color: #3a4140;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
}

.image-upload-info p span {
  font-weight: 600;
}
