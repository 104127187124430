.course-timeline ul li.MuiTimelineItem-missingOppositeContent::before {
  display: none;
}

.course-timeline .MuiTimeline-root {
  padding: 0px;
  margin: 0px;
}

.course-timeline .MuiTimeline-root li {
  min-height: auto;
}

.course-timeline .MuiTimelineContent-root {
  background-color: white;
  padding: 28px 32px 24.5px 26.5px;
  margin-left: 45px;
  border-radius: 8px;
  margin-bottom: 16px;
}

.course-timeline .MuiTimelineContent-root.Completed {
  background-color: unset;
  border: 1px solid #bdc0c7;
}

.course-timeline
  .MuiTimeline-root
  li
  .MuiTimelineSeparator-root.not-active-lesson
  .MuiTimelineDot-root,
.course-timeline
  .MuiTimeline-root
  li
  .MuiTimelineSeparator-root.not-active-lesson
  .MuiTimelineConnector-root {
  background-color: #35b84b;
}

.course-timeline .MuiTimeline-root li:last-child .MuiTimelineContent-root {
  margin-bottom: 0px;
}

.course-timeline .MuiTimeline-root li:last-child .MuiTimelineConnector-root {
  display: none;
}

.course-timeline .MuiTimeline-root .MuiTimelineDot-root {
  margin-top: 0px;
  margin-bottom: 0px;
  border-width: 0px;
  padding: 0px;
  width: 32px;
  height: 32px;
  background-color: #d2d3d3;
  box-shadow: unset;
  transform: translateY(32px);
}

.course-timeline .MuiTimeline-root .MuiTimelineDot-root.not-active-lesson {
  background-color: #35b84b;
}

.course-timeline .MuiTimeline-root .MuiTimelineDot-root .indicator {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #d2d3d3;
}

.course-timeline
  .MuiTimeline-root
  li
  .MuiTimelineSeparator-root.active-lesson
  .MuiTimelineDot-root
  .indicator {
  background: #35b84b;
}

.course-timeline .MuiTimeline-root .MuiTimelineConnector-root {
  background-color: #d2d3d3;
  width: 4px;
  transform: translateY(32px);
}

.course-timeline .MuiTimelineContent-root .course-info .date-time img {
  width: 20px;
  height: 20px;
  margin-right: 12px;
  margin-top: 2px;
}

.course-timeline .MuiTimelineContent-root .course-info .date-time .data-info p {
  font-size: 16px;
  line-height: 24px;
  color: #2c3033;
  font-weight: 500;
}

.course-timeline
  .MuiTimelineContent-root
  .course-info
  .date-time
  .data-info
  p:last-child {
  color: #3a4140;
  font-weight: 400;
}

.course-timeline .MuiTimelineContent-root .course-info .lesson-title {
  color: #2c3033;
  margin-left: 93px;
  max-width: 318px;
}

.course-timeline
  .MuiTimelineContent-root
  .course-actions
  .action-group
  .action-icon-holder {
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
}

.course-timeline .MuiTimelineContent-root .course-actions .start-lesson {
  background: #003399;
  color: white;
  box-shadow: 0px 2px 4px 0px #0033994d;
  border-radius: 4px;
  font-size: 18px;
  line-height: 26px;
  font-weight: 600;
  padding: 14px 24px;
  margin-right: 67px;
}

.course-timeline
  .MuiTimelineContent-root
  .course-actions
  .action-group
  .action-icon-holder:hover {
  background-color: #f2f5fa;
}

.course-timeline
  .MuiTimelineContent-root
  .course-actions
  .action-group
  .action-icon-holder
  img {
  width: 24px;
  height: 24px;
}

.course-timeline .lesson-end {
  width: 24px;
  height: 24px;
  cursor: pointer;
  align-self: center;
}

.course-timeline .lesson-end .custom-react-tooltip {
  max-width: 280px;
  padding-top: 8px !important;
  padding-bottom: 12px !important;
  margin-top: unset !important;
}

.matched-url-timeline .MuiTimelineContent-root {
  position: relative;
}

@-webkit-keyframes pulseBox {
  0% {
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 51, 153, 0.6);
  }
  100% {
    -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 51, 153, 0.9);
  }
}
@-moz-keyframes pulseBox {
  0% {
    -moz-box-shadow: 0px 0px 8px 0px rgba(0, 51, 153, 0.6);
  }
  100% {
    -moz-box-shadow: 0px 0px 12px 0px rgba(0, 51, 153, 0.9);
  }
}
@-o-keyframes pulseBox {
  0% {
    -o-box-shadow: 0px 0px 8px 0px rgba(0, 51, 153, 0.6);
  }
  100% {
    -o-box-shadow: 0px 0px 12px 0px rgba(0, 51, 153, 0.9);
  }
}
@keyframes pulseBox {
  0% {
    box-shadow: 0px 0px 8px 0px rgba(0, 51, 153, 0.6);
  }
  100% {
    box-shadow: 0px 0px 12px 0px rgba(0, 51, 153, 0.9);
  }
}

.matched-url-timeline .MuiTimelineContent-root::after {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  z-index: -1;
  border-radius: 8px;
  -webkit-animation-name: pulseBox;
  -moz-animation-name: pulseBox;
  -o-animation-name: pulseBox;
  animation-name: pulseBox;
  -webkit-animation-duration: 0.7s;
  -moz-animation-duration: 0.7s;
  -o-animation-duration: 0.7s;
  animation-duration: 0.7s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-direction: alternate;
  -moz-animation-direction: alternate;
  -o-animation-direction: alternate;
  animation-direction: alternate;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  -o-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
  border: 0px;
}

@media screen and (max-width: 1190px) {
  .course-timeline
    .MuiTimelineContent-root
    .course-info
    .date-time
    .data-info
    p {
    font-size: 14px;
    line-height: 22px;
  }
  .course-timeline .MuiTimelineContent-root .course-info .lesson-title {
    margin-left: 65px;
    font-size: 16px;
    line-height: 24px;
    margin-right: 5px;
    max-width: 290px;
  }
  .course-timeline .MuiTimelineContent-root .course-actions .start-lesson {
    font-size: 16px;
    line-height: 24px;
    padding: 14px;
    margin-right: 30px;
  }
}

@media screen and (max-width: 900px) {
  .course-timeline .MuiTimelineContent-root {
    overflow-x: auto;
  }
  .course-timeline .course-info {
    min-width: 450px;
  }
  .course-timeline .MuiTimelineContent-root .course-actions .start-lesson {
    min-width: fit-content;
    padding: 14px 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
