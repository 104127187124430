.pre-join-lesson {
  width: auto;
  min-width: 300px;
  height: auto;
  background-color: white;
  position: relative;
}

.pre-join-lesson .MuiButton-startIcon {
  align-items: center;
}
