.education-teachers h1 {
  margin-bottom: 18px;
}

.education-teachers .primary-teachers {
  margin-right: 20px;
}

.education-teachers .primary-teachers .default-teacher-card {
  width: 400px;
}

.education-teachers .primary-teachers .secondary-teachers {
  margin-top: 64px;
  margin-bottom: 77px;
}

.education-teachers .default-teacher-card {
  flex: 1;
}

.education-teachers .secondary-teachers {
  width: 100%;
}

.education-teachers .secondary-teachers .secondary-content {
  flex-wrap: wrap;
  justify-content: space-between;
}

.education-teachers
  .secondary-teachers
  .secondary-content
  .default-teacher-card {
  margin-bottom: 16px;
  width: 48%;
  flex: unset;
}

.education-teachers .default-teacher-card .teacher-information {
  word-break: break-all;
}

@media screen and (max-width: 1366px) {
  .education-teachers
    .secondary-teachers
    .secondary-content
    .default-teacher-card {
    flex: unset;
    width: 48%;
  }
  .education-teachers .default-teacher-card .teacher-information p {
    word-break: break-all;
  }
}

@media screen and (max-width: 1190px) {
  .education-teachers {
    flex-direction: column;
  }
  .education-teachers .primary-teachers {
    margin-right: 0px;
    margin-bottom: 40px;
  }
  .education-teachers .default-teacher-card {
    max-width: unset;
    width: 48% !important;
    flex: unset;
  }
}

@media screen and (max-width: 768px) {
  .education-teachers .default-teacher-card {
    width: 100% !important;
  }
}

@media screen and (max-width: 768px) {
  .education-teachers h1 {
    font-size: 27px;
    line-height: 30px;
  }
}
