#education-documents .upload-file {
  margin-top: 24px;
}

#education-documents .upload-file .MuiFormControl-root {
  width: auto;
  background: white;
  margin: 0px 12px 0px 0px;
}

#education-documents .upload-file .MuiFormControl-root .MuiInputBase-root {
  height: 40px;
}

#education-documents
  .upload-file
  .MuiFormControl-root
  .MuiInputBase-root
  input {
  box-sizing: border-box;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 100%;
  padding-left: 14px;
}

#education-documents
  .upload-file
  .MuiFormControl-root
  .MuiInputBase-root
  input::placeholder {
  color: #868a89;
}

#education-documents
  .upload-file
  .MuiFormControl-root
  .MuiInputBase-root
  .MuiInputAdornment-positionStart {
  margin-right: 0px;
}

#education-documents .upload-file .MuiButton-label {
  font-size: 16px;
}

#education-documents .upload-file .MuiButtonBase-root {
  min-height: 39px;
  height: 39px;
}

#education-documents .upload-file .upload-document {
  height: auto;
  border-radius: 4px;
}

#education-documents .upload-document.empty-state-upload {
  display: none;
  visibility: hidden;
  opacity: 0;
}

#education-documents .upload-file .upload-document label {
  padding: 6px 16px;
  border-radius: 4px;
  border: 1px solid #bdc0c7;
  color: #3a4140;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  cursor: pointer;
}

#education-documents .upload-file .upload-document label img {
  margin-right: 4px;
}

#education-documents .upload-file .upload-document label input {
  display: none;
  opacity: 0;
  visibility: hidden;
}

#education-documents .upload-file .upload-doc-btn .MuiButton-startIcon {
  height: 24px;
  min-height: 24px;
}

#education-documents .documents-table {
  margin-top: 44px;
  margin-bottom: 56px;
}

#education-documents .documents-table .scrollable-area .table-header {
  height: auto;
  padding-left: 34px;
  margin-bottom: 8px;
}

#education-documents
  .documents-table
  .scrollable-area
  .table-header
  .header-cell {
  flex: 1;
}

#education-documents
  .documents-table
  .scrollable-area
  .table-header
  .header-cell:last-child {
  margin-right: 0px;
}

#education-documents
  .documents-table
  .scrollable-area
  .table-header
  .header-cell
  p {
  color: #686d6c;
  margin-right: 14px;
}

#education-documents
  .documents-table
  .scrollable-area
  .table-header
  .header-cell:first-child
  p {
  margin-right: 82px;
}

#education-documents
  .documents-table
  .scrollable-area
  .table-header
  .header-cell
  .sort-icons
  img:first-child {
  margin-bottom: 2px;
}

#education-documents .documents-table .scrollable-area .table-data .data-row {
  box-shadow: 0px 1px 2px 0px #00000026;
  background-color: white;
  margin-bottom: 8px;
  border-radius: 8px;
  height: 72px;
  padding-left: 34px;
  position: relative;
}

#education-documents
  .documents-table
  .scrollable-area
  .table-data
  .data-row
  .row-cell {
  flex: 1;
}

#education-documents
  .documents-table
  .scrollable-area
  .table-data
  .data-row
  .row-cell
  img {
  margin-right: 14px;
}

#education-documents
  .documents-table
  .scrollable-area
  .table-data
  .data-row
  .row-cell
  p {
  color: #3a4140;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}

#education-documents
  .documents-table
  .scrollable-area
  .table-data
  .data-row:last-child {
  margin-bottom: 0px;
}

#education-documents
  .documents-table
  .scrollable-area
  .table-data
  .data-row
  .action-icon-holder {
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
}

#education-documents
  .documents-table
  .scrollable-area
  .table-data
  .data-row
  .action-icon-holder:hover {
  background-color: #f2f5fa;
}

.upload-document {
  position: relative;
  overflow: hidden;
}

.upload-document .loader {
  width: 100%;
  height: 100%;
  z-index: 1000;
  user-select: none;
  pointer-events: none;
}

.document-actions {
  position: absolute;
  top: 0;
  right: 12px;
  height: 100%;
}

.swipe-for-more {
  margin-top: 20px;
  display: none;
}

@media screen and (max-width: 1000px) {
  #education-documents
    .documents-table
    .scrollable-area
    .table-data
    .data-row
    .row-cell
    p {
    font-size: 15px;
    line-height: 19px;
    max-width: 200px;
  }
}

@media screen and (max-width: 680px) {
  #education-documents .documents-table {
    overflow: hidden;
  }
  #education-documents .documents-table .scrollable-area {
    overflow-x: auto;
  }
  #education-documents .documents-table .scrollable-area .table-header,
  #education-documents .documents-table .scrollable-area .table-data {
    width: 1000px;
  }
  .swipe-for-more {
    display: block;
  }
}

@media screen and (max-width: 480px) {
  #education-documents .upload-file {
    flex-direction: column-reverse;
  }
  #education-documents .upload-file .MuiFormControl-root {
    margin-top: 12px;
    margin-right: 0px;
  }
  #education-documents .upload-file .upload-document label {
    display: inline-flex;
  }
}
