.empty-state {
  /* padding-top: 112px; */
  text-align: center;
}

.empty-state.filters-empty {
  margin-top: 64px;
}

.empty-state img {
  margin-bottom: 53px;
}

.empty-state .content {
  max-width: 524px;
}

.empty-state .content h6 {
  margin-bottom: 20px;
}

.link-btn {
  margin-top: 40px;
}

.link-btn a {
  font-weight: 600;
  background-color: #003399;
  padding: 15px 24px;
  box-shadow: 0px 2px 4px rgba(0, 51, 153, 0.3);
  border-radius: 4px;
  color: #ffffff;
  font-size: 18px;
  line-height: 24px;
}

.add-document label {
  background-color: #003399;
  box-shadow: 0px 2px 4px rgba(0, 51, 153, 0.3);
  color: #ffffff;
  padding: 15px 24px;
  border-radius: 4px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  cursor: pointer;
}

.add-document label input {
  display: none;
  opacity: 0;
  visibility: hidden;
}

@media screen and (max-width: 768px) {
  .empty-state .image {
    max-width: 200px;
  }
  .empty-state .content {
    max-width: 400px;
  }
  .empty-state .content h6 {
    font-size: 30px;
    line-height: 34px;
  }
}

@media screen and (max-width: 414px) {
  .empty-state .image {
    max-width: 150px;
  }
  .empty-state .content {
    max-width: 300px;
  }
  .empty-state .content h6 {
    font-size: 21px;
    line-height: 29px;
  }
}
