.teacher-card .card-header {
  padding: 24px 27px 24px 24px;
}

.teacher-card.blocked-teacher .card-header {
  opacity: 0.4;
}

.teacher-card.blocked-teacher .card-header .block-teacher-btn {
  user-select: none;
  pointer-events: none;
  cursor: default;
}

.teacher-card .card-header .teacher-info {
  margin-left: 16px;
  margin-right: 8px;
  word-break: break-all;
}

.teacher-card .card-header .block-teacher-btn {
  cursor: pointer;
}
