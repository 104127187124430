.notification-dropdown {
  width: 300px;
  height: auto;
  background-color: white;
  box-shadow: 0px 3px 6px 0px #00000029;
  border: 1px solid #bdc0c7;
  border-radius: 8px;
  margin-top: 3px;
}

.notification-dropdown .title {
  background: #edeeed;
  padding-left: 23px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.notification-dropdown .title .MuiTypography-root {
  color: #3a4140;
}

.notification-dropdown .list {
  height: auto;
  max-height: 365px;
  overflow-y: auto;
}

.notification-dropdown .list .notification {
  border-bottom: 1px solid #edeeed;
  margin-right: 0px !important;
  padding: 24px;
}

.notification-dropdown .list .notification img {
  width: 24px;
  height: 24px;
  margin-right: 16px;
}

.notification-dropdown .list .notification p {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  letter-spacing: -0.015em;
  color: #3a4140;
  margin-bottom: 0px;
}

.notification-dropdown .list .notification p a {
  color: #0c59f2;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 8px;
}

.notification-dropdown .view-all-notifications {
  border: 1px solid #bdc0c7;
  border-radius: 8px;
  box-shadow: 0px 3px 6px 0px #00000029;
  color: #3a4140;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  height: 38px;
  margin: 16px;
}

@media screen and (max-width: 300px) {
  .notification-dropdown {
    width: 200px;
  }
}
