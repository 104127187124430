.add-question .content {
  padding: 32px 40px 24px 40px;
}

.add-question .header {
  margin-bottom: 20px;
}

.add-question .header h1 {
  color: #2c3033;
}

.add-question .header img {
  cursor: pointer;
}

.add-question .modal-content .qa-editor {
  width: 500px;
}

@media screen and (max-width: 600px) {
  .add-question {
    width: 450px;
  }
  .add-question .content .header {
    flex-direction: row;
    align-items: center;
  }
  .add-question .modal-content .qa-editor {
    width: 100%;
  }
}

@media screen and (max-width: 460px) {
  .add-question {
    width: 380px;
  }
}

@media screen and (max-width: 390px) {
  .add-question {
    width: 360px;
  }
}

@media screen and (max-width: 365px) {
  .add-question {
    width: 340px;
  }
}

@media screen and (max-width: 345px) {
  .add-question {
    width: 280px;
  }
}
