.join-education {
  width: 540px;
}

.join-education .modal-header {
  padding: 40px 66px;
  text-align: center;
}

.join-education .modal-header .avatar {
  width: 80px;
  height: 80px;
}

.join-education .modal-header .MuiTypography-h1 {
  color: #2c3033;
}

.join-education .modal-header .teacher-label {
  margin-bottom: 40px;
  margin-top: 16px;
}

.join-education .modal-header .teacher-label span {
  font-size: 18px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 24px;
  letter-spacing: -0.015em;
  text-align: left;
  color: #868a89;
}

.join-education .modal-header .description {
  margin-top: 12px;
  margin-bottom: 32px;
}

.join-education .modal-header .upload-info .info-icon {
  height: 13px;
  margin-right: 5px;
}

.join-education .modal-header .upload-info {
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 15px;
  color: #868a89;
}

.join-education .footer .student-apply-education {
  margin-right: 12px;
}
.join-education .footer button:last-child {
  margin-left: 0px;
}

@media screen and (max-width: 600px) {
  .join-education {
    width: 450px;
  }
}

@media screen and (max-width: 480px) {
  .join-education {
    width: 400px;
  }
}

@media screen and (max-width: 415px) {
  .join-education {
    width: 360px;
  }
}

@media screen and (max-width: 370px) {
  .join-education {
    width: 320px;
  }
  .join-education .modal-header {
    padding: 40px 25px;
  }
}

@media screen and (max-width: 330px) {
  .join-education {
    width: 280px;
  }
}
