.filter-select {
  margin-right: 8px;
  position: relative;
}

.filter-select.hub-select {
  width: 48%;
  margin-right: 0px;
}

.filter-select .select-label {
  position: absolute;
  top: -7px;
  left: 9px;
  color: #3a4140;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  background-color: white;
  z-index: 1;
  padding: 0px 6px;
}

.filter-select.error-on-select .ant-select {
  border: 1px solid #df2935;
}

.filter-select .ant-select {
  min-width: 150px;
  width: 150px;
  height: 48px;
  min-height: 48px;
  max-height: 48px;
  background: #fafcff;
  border: 1px solid #bdc0c7;
  border-radius: 4px;
}

.filter-select.hub-select .ant-select {
  width: 100%;
  min-width: 218px;
  height: 56px;
  min-height: 56px;
  max-height: 56px;
  background: white;
}

.filter-select
  .ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  height: 100%;
  align-items: center;
  background-color: unset;
  border: unset;
  border-radius: unset;
  padding: 0px 16px;
}

.filter-select
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: unset;
  box-shadow: unset;
}

.filter-select .ant-select-arrow {
  background-image: url("/src/assets/images/icons/filter-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  width: 10px;
  height: 5px;
  top: 28px;
}

.filter-select.hub-select .ant-select-arrow {
  background-image: url("/src/assets/images/icons/arrows.svg");
  width: 24px;
  height: 24px;
  top: 23px;
}

.filter-select
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.ant-select-selection-placeholder {
  color: #686d6c;
  font-size: 15px;
  font-weight: 400;
}

.filter-select .ant-select-arrow span {
  display: none;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: unset;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #edeeed;
  color: #3a4140;
}

.ant-select-item {
  color: #3a4140;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.ant-select-item:hover {
  background-color: #edeeed !important;
}

.ant-select-dropdown {
  padding: 16px 0px;
  border: 1px solid #bdc0c7;
  box-shadow: 0px 5px 10px 0px #00000040;
  border-radius: 4px;
  width: auto !important;
  min-width: 200px !important;
}
