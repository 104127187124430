.hub-header {
  height: 400px;
  background-color: rgba(0, 17, 102, 0.7);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
}

.hub-header.auto {
  background-size: auto;
  background-color: unset;
}

.hub-header::after {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  content: "";
  background-color: rgba(0, 17, 102, 0.7);
}

.hub-header .hub-info {
  z-index: 1;
}

.hub-header .hub-info p {
  color: #fc7914;
  font-size: 28px;
  line-height: 54px;
  font-weight: 600;
}

.hub-header .hub-info img {
  height: 50px;
  margin-bottom: 10px;
}

.hub-header .hub-info .MuiTypography-subtitle1 {
  color: white;
}

.hub-header .hub-actions {
  z-index: 1;
}

.hub-header .hub-actions .action {
  width: 45px;
  height: 45px;
  background: #f9f9f9b2;
  border: 1px solid #bdc0c7;
  box-shadow: 0px 1px 2px 0px #00000029;
  border-radius: 4px;
  cursor: pointer;
}

.hub-header .hub-actions .action:nth-child(2) {
  margin-left: 12px;
}

.hub-header .hub-actions .action.action-edit img {
  width: 22.5px;
  height: 22.5px;
}

.hub-header .hub-actions .action.action-block img {
  width: 25px;
  height: 25px;
}

.single-hub .single-hub-container {
  padding-top: 80px;
  padding-bottom: 120px;
}

.single-hub .single-hub-container .info-block .contact-details {
  max-width: 505px;
  min-width: 505px;
  padding: 38px 40px 48px 40px;
  box-shadow: 0px 2px 4px 0px #00000029;
  border-radius: 8px;
  background-color: white;
  position: relative;
  z-index: 1;
  margin-top: -144px;
}

.single-hub .single-hub-container .info-block .contact-details h1 {
  color: #2c3033;
  margin-bottom: 20px;
}

.single-hub .single-hub-container .info-block .contact-details .details p {
  font-size: 12px;
  line-height: 14px;
  text-transform: uppercase;
  color: #868a89;
}

.single-hub .single-hub-container .info-block .contact-details .details {
  margin-bottom: 24px;
}

.single-hub .single-hub-container .info-block .contact-details .details h4 {
  font-weight: 500;
  color: #3a4140;
  word-wrap: break-word;
}

.single-hub
  .single-hub-container
  .info-block
  .contact-details
  .details:last-child {
  margin-bottom: 0px;
}

.single-hub .single-hub-container .admin-details .MuiTypography-subtitle2,
.single-hub
  .single-hub-container
  .teachers-educations
  .MuiTypography-subtitle2 {
  color: #2c3033;
  margin-bottom: 24px;
}

.single-hub .single-hub-container .info-block .admin-details {
  margin-top: 40px;
  margin-bottom: 80px;
}

.single-hub .single-hub-container .teachers-educations .registered-data {
  box-shadow: 0px 2px 4px 0px #00000026;
  background-color: white;
  border-radius: 12px;
  padding: 23px 27px;
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  color: #3a4140;
}

.single-hub
  .single-hub-container
  .teachers-educations
  .registered-data:first-child {
  margin-right: 20px;
}

.single-hub .single-hub-container .teachers-educations .registered-data span {
  font-weight: 600;
  display: inline-block;
  margin-left: 6px;
}

.single-hub .single-hub-container .teachers-educations .registered-data img {
  width: 33px;
  height: 33px;
  margin-right: 12px;
}

.default-teacher-card {
  background-color: white;
  box-shadow: 0px 2px 4px 0px #00000026;
  border-radius: 12px;
  padding: 24px;
  max-width: 400px;
}

.default-teacher-card img {
  width: 50px;
  height: 50px;
  margin-right: 16px;
}

.default-teacher-card .teacher-information p {
  font-size: 16px;
  line-height: 24px;
  color: #868a89;
  font-weight: 400;
  word-break: break-all;
}

.hub-header .information {
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
}

@media screen and (max-width: 1190px) {
  .single-hub-container .info-block {
    flex-direction: column-reverse;
  }
  .single-hub .single-hub-container .info-block .contact-details {
    width: 100%;
    max-width: 100%;
    min-width: unset;
  }
  .hub-header {
    padding-left: 0px;
    padding-right: 0px;
  }
  .hub-header .information {
    max-width: 90%;
  }
}

@media screen and (max-width: 768px) {
  .single-hub-container .default-teacher-card {
    width: 100%;
    max-width: 100%;
  }

  .single-hub-container .teachers-educations .registered-data-holder {
    flex-direction: column;
  }

  .single-hub-container .teachers-educations .registered-data {
    width: 100%;
    margin-right: 0px !important;
    margin-left: 0px;
    justify-content: flex-start;
  }

  .single-hub-container .teachers-educations .registered-data:first-child {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 520px) {
  .hub-header .information {
    height: 100%;
    position: relative;
  }
  .hub-header .hub-actions {
    position: absolute;
    right: 0;
    top: 30px;
  }
  .hub-header .hub-actions .action {
    width: 35px;
    height: 35px;
  }
  .hub-header .hub-actions .action img {
    width: 15px !important;
    height: 15px !important;
  }
}

@media screen and (max-width: 414px) {
  .single-hub .single-hub-container .admin-details .MuiTypography-subtitle2,
  .single-hub
    .single-hub-container
    .teachers-educations
    .MuiTypography-subtitle2 {
    font-size: 28px;
    line-height: 32px;
  }
  .single-hub .single-hub-container .info-block .contact-details .details h4 {
    font-size: 16px;
    line-height: 23px;
  }
  .hub-header .hub-info .MuiTypography-subtitle1 {
    font-size: 28px;
    line-height: 32px;
  }
}
