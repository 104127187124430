.add-event {
  padding-top: 32px;
  width: 539px;
}

.add-event .event-header {
  padding: 0px 40px;
}

.add-event .event-header h1 {
  color: #2c3033;
}

.add-event .event-header img {
  cursor: pointer;
}

.add-event form .form-content {
  padding: 0px 40px;
  margin: 24px 0px;
}

.add-event form .form-content .date-time-box {
  margin-top: 14.5px;
}

.add-event form .form-content .date-time-box .app-date-time-picker {
  width: 48%;
}

.add-event form .form-content .date-time-box .app-date-time-picker:last-child {
  margin-left: 11px;
  margin-right: 0px;
}

@media screen and (max-width: 600px) {
  .add-event {
    width: 400px;
  }
}

@media screen and (max-width: 414px) {
  .add-event {
    width: 380px;
  }
  .add-event form .form-content .date-time-box .app-date-time-picker {
    width: 100%;
  }
  .add-event
    form
    .form-content
    .date-time-box
    .app-date-time-picker:last-child {
    margin-left: 0px;
    margin-top: 14.5px;
  }
}

@media screen and (max-width: 385px) {
  .add-event {
    width: 360px;
  }
}

@media screen and (max-width: 366px) {
  .add-event {
    width: 340px;
  }
}
