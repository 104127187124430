.upload-input {
  height: auto;
  border: 1px solid #bdc0c7;
  border-radius: 4px;
  padding: 9px;
}

.upload-input label {
  padding: 6px 16px;
  border-radius: 4px;
  border: 1px solid #bdc0c7;
  color: #3a4140;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
  margin-right: 16px;
  cursor: pointer;
}

.upload-input label img {
  margin-right: 4px;
}

.upload-input label input {
  display: none;
  opacity: 0;
  visibility: hidden;
}

.upload-input p {
  font-size: 15px;
  line-height: 15px;
  font-weight: 400;
  color: #868a89;
  text-overflow: ellipsis;
  width: 230px;
  white-space: nowrap;
  overflow: hidden;
}

.upload-input p.file-name {
  text-align: left;
}

.upload-input label.file-name {
  min-width: 165px;
}

.upload-input-holder {
  position: relative;
}

.upload-input-holder img.trash {
  position: absolute;
  right: 9px;
  top: -1px;
  bottom: 0;
  cursor: pointer;
  margin: auto;
}
