.mobile-drawer
  .ant-drawer-content-wrapper
  .ant-drawer-wrapper-body
  .ant-drawer-header {
  padding: 17px !important;
  background-color: #f9f9f9 !important;
}

.mobile-drawer
  .ant-drawer-content-wrapper
  .ant-drawer-wrapper-body
  .ant-drawer-header
  .ant-drawer-title {
  font-size: 18px !important;
  line-height: 26px !important;
  color: #3a4140 !important;
}

.mobile-drawer
  .ant-drawer-content-wrapper
  .ant-drawer-wrapper-body
  .ant-drawer-header
  .ant-drawer-close {
  padding: 0px;
  height: 100%;
  width: 14px;
  right: 17px;
}

.mobile-drawer
  .ant-drawer-content-wrapper
  .ant-drawer-wrapper-body
  .ant-drawer-header
  .ant-drawer-close
  img {
  width: 100%;
}

.mobile-drawer
  .ant-drawer-content-wrapper
  .ant-drawer-wrapper-body
  .ant-drawer-body {
  padding: 27.5px 17px !important;
}

.mobile-drawer
  .ant-drawer-content-wrapper
  .ant-drawer-wrapper-body
  .ant-drawer-body
  .mobile-links {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 4px;
}

.mobile-drawer
  .ant-drawer-content-wrapper
  .ant-drawer-wrapper-body
  .ant-drawer-body
  .mobile-links
  a {
  font-size: 16px;
  color: #3a4140;
  line-height: 27px;
  margin-bottom: 30px;
}

.mobile-drawer
  .ant-drawer-content-wrapper
  .ant-drawer-wrapper-body
  .ant-drawer-body
  .mobile-links
  a.active-nav-item {
  color: #fc7914;
}

.mobile-drawer
  .ant-drawer-content-wrapper
  .ant-drawer-wrapper-body
  .ant-drawer-body
  .mobile-links
  a
  img {
  margin-right: 10px;
}

.mobile-drawer
  .ant-drawer-content-wrapper
  .ant-drawer-wrapper-body
  .ant-drawer-footer {
  background-color: white !important;
  padding: 32px 17px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-drawer
  .ant-drawer-content-wrapper
  .ant-drawer-wrapper-body
  .ant-drawer-body
  .language-switcher-mobile {
  position: relative;
  padding: 26px 0px;
}

.mobile-separator {
  width: calc(100% + 34px);
  position: absolute;
  left: -17px;
  bottom: 0;
  height: 1px;
  background-color: #edf0f5;
}

.language-switcher-mobile .bih,
.language-switcher-mobile .eng {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  color: #3a4140;
  text-transform: uppercase;
}

.language-switcher-mobile div:first-child {
  margin-bottom: 20px;
}

.language-switcher-mobile div.active {
  font-weight: 600;
}

.mobile-drawer .logout {
  display: flex;
  margin-top: 36.5px;
}

.mobile-drawer .logout p {
  font-size: 16px;
  line-height: 27px;
  font-family: 500;
  color: #3a4140;
  margin-left: 11px;
}
