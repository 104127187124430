.qa-item-content {
  height: auto;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 15px;
}

.qa-item-content.teacher-post {
  background-color: #edeae8;
}

.qa-item-content:last-child {
  margin-bottom: 0px;
}

.qa-item-content .qa-item-container {
  height: auto;
  padding: 24px 24px 38.5px 24px;
}

.qa-item-content .answers-list {
  padding: 0px 65px;
}

.qa-item-content .answers-list .qa-item {
  margin-top: 47px;
}

.qa-item-content .answers-list .qa-item:nth-child(1) {
  margin-top: 0px;
}

.qa-item-content .qa-item .icon {
  width: 50px;
  min-width: 50px;
  height: 50px;
  margin-right: 16px;
}

.qa-item-content
  .qa-item
  .comment-content
  .comment-header
  .user-info
  .user-type {
  color: #868a89;
}

.qa-item-content .qa-item .comment-content .comment-header p.date span {
  margin-left: 3px;
}

.qa-item-content .qa-item .comment-content .comment-header p {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #686d6c;
}

.qa-item-content .qa-item .comment-content .comment-body {
  margin-top: 20px;
}

.qa-item-content .qa-item .comment-content .comment-body .comment-text {
  font-weight: 400;
  color: #3a4140;
  word-break: break-all;
}

.qa-item-content .qa-item .comment-content .comment-body .comment-text p {
  color: #3a4140;
}

.qa-item-content
  .qa-item
  .comment-content
  .comment-body
  .comment-text
  p
  strong {
  font-weight: 600;
}

.qa-item-content .qa-item .comment-content .comment-body .hold-action-buttons {
  margin-top: 19.5px;
}

.qa-item-content .qa-item .comment-content .comment-body .reply {
  padding: 10px 24px;
  border: 1px solid #bdc0c7;
  border-radius: 4px;
  font-size: 17px;
  line-height: 26px;
  font-weight: 600;
  color: #3a4140;
  cursor: pointer;
  display: inline-flex;
  margin-right: 12px;
}

.qa-item-content .qa-item .comment-content .comment-body .reply img {
  margin-right: 10px;
}

.qa-item-content .all-comments {
  height: auto;
  margin-top: 9px;
}

.qa-item-content .all-comments .qa-editor-container {
  margin-top: 40px;
  margin-bottom: 46px;
}

.qa-item-content .all-comments .qa-editor-container .icon {
  width: 50px;
  min-width: 50px;
  height: 50px;
  margin-right: 14px;
}

.qa-item-content .all-comments .qa-editor-container .qa-editor {
  width: 632px;
}

.qa-item-content .all-comments .action {
  cursor: pointer;
  background: #f9f9f9;
  height: 64px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.qa-item-content.teacher-post .all-comments .action {
  background-color: #dedbd9;
}

.qa-item-content .all-comments p.comments-length {
  font-size: 17px;
  line-height: 26px;
  color: #3a4140;
  font-weight: 600;
  margin: 0px 10px;
}

.qa-item-content .all-comments img.all-comments-img {
  width: 16px;
  height: 16px;
}

@media screen and (max-width: 480px) {
  .qa-item-content .qa-item .icon {
    width: 35px;
    min-width: 35px;
    height: 35px;
  }

  .qa-item-content .qa-item .user-info h5 {
    font-size: 16px;
  }

  .qa-item-content .qa-item .comment-content .comment-header p.date {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 13px;
  }

  .qa-item-content .answers-list {
    padding: 0px 35px;
  }
  .qa-item-content .all-comments .qa-editor-container {
    margin-top: 25px;
    margin-bottom: 30px;
  }
  .qa-item-content .all-comments .qa-editor-container .icon {
    width: 35px;
    min-width: 35px;
    height: 35px;
  }
  .qa-item-content .all-comments .qa-editor-container .icon img {
    width: 100%;
  }
  .qa-item-content .all-comments .qa-editor-container .qa-editor {
    width: 100%;
  }
}

@media screen and (max-width: 360px) {
  .qa-item-content .qa-item .user-info h5 {
    font-size: 14px;
    line-height: 20px;
  }
}
