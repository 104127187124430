.confirmation-modal-content .header {
  padding: 40px 40px 23px 40px;
}

.confirmation-modal-content .header .icon {
  width: 60px;
  height: 60px;
  min-width: 60px;
  margin-right: 20px;
  border-radius: 6px;
}

.confirmation-modal-content .header .icon-block {
  background: #ffa114;
}

.confirmation-modal-content .header .icon-delete {
  background: #df2935;
}

.confirmation-modal-content .header .information {
  max-width: 300px;
}

.confirmation-modal-content .header .information p {
  margin-top: 7.5px;
}

.confirmation-modal-content .header .information p.description {
  font-weight: 400;
  line-height: 24px;
}

@media screen and (max-width: 560px) {
  .confirmation-modal-content {
    width: 460px;
  }
}

@media screen and (max-width: 460px) {
  .confirmation-modal-content {
    width: 380px;
  }
}

@media screen and (max-width: 380px) {
  .confirmation-modal-content {
    width: 360px;
  }
}

@media screen and (max-width: 360px) {
  .confirmation-modal-content {
    width: 320px;
  }
}

@media screen and (max-width: 330px) {
  .confirmation-modal-content {
    width: 280px;
  }
}
