#teachers-list .teachers-header {
  margin-top: 32px;
  margin-bottom: 20px;
  height: auto;
}

#teachers-list .filters img.reload-filters {
  cursor: pointer;
  margin-left: 13px;
}

#teachers-list .filters .MuiTypography-h5 {
  margin-right: 16px;
  font-weight: 500;
}

#teachers-list .list {
  width: calc(100% + 20px);
  margin-left: -10px;
}

#teachers-list .list .teacher-card {
  margin: 16px 10px 0px 10px;
  flex-basis: 31.7%;
}

@media screen and (max-width: 1280px) {
  #teachers-list .list .teacher-card {
    flex-basis: 31.4%;
  }
}

@media screen and (max-width: 1190px) {
  #teachers-list .list .teacher-card {
    flex-basis: 48%;
  }
}

@media screen and (max-width: 1028px) {
  #teachers-list .list {
    width: 100%;
    margin-left: 0px;
  }
  #teachers-list .list .teacher-card {
    flex-basis: unset;
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
}
