* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
}
html,
body,
#root {
  width: 100%;
  height: 100%;
}

body {
  position: relative;
  font-family: "Montserrat", sans-serif !important;
  background-color: #ebf0fa;
  font-variant: unset;
}

p {
  color: #686d6c;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: -0.015em;
  margin: 0px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input::-ms-clear {
  display: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

::-ms-reveal {
  display: none !important;
}

input:-internal-autofill-selected {
  background: white;
  background-color: white !important;
}

.custom-react-tooltip {
  background-color: white !important;
  box-shadow: 0px 2px 4px 0px #00000029 !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  line-height: 21px !important;
  font-weight: 400;
  letter-spacing: -0.015em;
  color: #000000 !important;
  padding-left: 11px !important;
  padding-right: 12px !important;
  padding-top: 3px !important;
  padding-bottom: 5px !important;
  margin-top: -13px !important;
}

.custom-react-tooltip.show {
  opacity: 1 !important;
}

.custom-react-tooltip::after,
.custom-react-tooltip::before {
  display: none !important;
}

.MuiPagination-root {
  margin-top: 56px;
}
