.MuiPagination-root {
  width: 100%;
  height: auto;
}
.MuiPagination-root .MuiPagination-ul {
  justify-content: center;
}
.MuiPagination-root .MuiPagination-ul li button {
  width: 40px;
  height: 40px;
  color: #3a4140;
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  min-height: 40px;
  max-height: 40px;
}
.MuiPagination-root .MuiPagination-ul li button.Mui-selected {
  background-color: #d3def2;
  color: #2c3033;
  font-weight: 500;
}
