.app-modal {
  width: 100%;
  height: 100vh;
  background-color: rgba(77, 77, 77, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1050;
  overflow-y: auto;
  padding: 100px 0px;
}

.app-modal .app-modal-container {
  width: auto;
  height: auto;
  background-color: white;
  box-shadow: 0px 20px 40px 0px #00000040;
  border-radius: 12px;
  word-wrap: break-word;
  /* margin-top: 128px; */
}

.app-modal .app-modal-container .app-modal-content .footer {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
}

.footer {
  padding: 24px 40px;
  background: #f9f9f9;
}

.footer.delete-footer {
  padding-left: 115px;
}

.footer button {
  width: auto;
  font-size: 18px !important;
}

.footer button:last-child {
  margin-left: 12px;
}
